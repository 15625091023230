import React, {ReactElement} from "react";
import {PlaceholderFragment} from "shared/PlaceholderFragment";
import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {Conversation} from "./types";
import {Action} from "shared/types";
import {SettingsOutlined} from "@mui/icons-material";
import {TabInfo} from "shared/TabsContainer";
import {ConversationMembersFragment} from "./ConversationMembersFragment";
import {ConversationDisplayName} from "./ConversationsFragment";
import {MembersKey} from "shared/entities";
import {InboxKey} from "app/shared/messages/messages";
import {ChatThreads} from "app/shared/messages/chat_messages";
import {ChatThread, ChatThreadKey} from "app/shared/messages/chat_protocol";
import {AppChatFragment} from "app/products/chat/messages/AppChatFragment";
import {ChatBar} from "app/shared/messages/ChatBar";
import {getMemberAuth} from "shared/auth";

export type ConversationFragmentProps = PageFragmentProps & {
  conversation: Conversation,
}

type ConversationFragmentState = PageFragmentState & {
  thread?: ChatThread,
}

enum ConversationTabType {
  CHAT = "chat",
  MEMBERS = "members",
}

const TABS: TabInfo<ConversationTabType>[] = [
  {
    type: ConversationTabType.CHAT,
    text: "Chat",
  },
  {
    type: ConversationTabType.MEMBERS,
    text: "Members",
  },
];

export class ConversationFragment extends PageFragment<ConversationFragmentProps, ConversationFragmentState> {

  private readonly memberAuth = getMemberAuth();

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
      title: ConversationDisplayName(this.props.conversation),
      selectedToolbarTabId: ConversationTabType.CHAT,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    const membersKey = MembersKey.DEFAULT;
    const inboxKey = InboxKey.from(this.memberAuth.member.memberId, this.props.conversation.convoId);
    const thread = await ChatThreads.getInstance(membersKey, inboxKey).getOrLoadThread(ChatThreadKey.createDefault());
    this.setState({
      title: ConversationDisplayName(this.props.conversation),
      selectedToolbarTabId: ConversationTabType.CHAT,
      thread: thread,
    });
  }

  componentDidUpdate(prevProps: Readonly<ConversationFragmentProps>, prevState: Readonly<ConversationFragmentState>, snapshot?: any) {
    if (prevProps.conversation.convoId !== this.props.conversation.convoId) {
      this.reload();
    }
  }

  createToolbarActions(): Action[] {
    return [
      new Action("Settings", () => this.showSettings(), SettingsOutlined),
    ];
  }

  getToolbarTabs(): TabInfo<any>[] {
    return TABS;
  }

  renderContent(): ReactElement {
    switch (this.state.selectedToolbarTabId) {
      case ConversationTabType.CHAT:
        return <AppChatFragment
          path={this.props.path}
          personaId={this.props.conversation.personaId}
          thread={this.state.thread}
          chatBarFlags={ChatBar.FLAG_FORMAT_TEXT} titleHidden/>;
      case ConversationTabType.MEMBERS:
        return <ConversationMembersFragment conversation={this.props.conversation}/>;
    }
    return <PlaceholderFragment path={this.props.path}/>
  }

  private showSettings() {

  }
}
