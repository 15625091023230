import {JsonObject} from "../../../shared/json/json-object";
import {ContainerKey, MembersKey} from "../../../shared/entities";
import {JsonProperty} from "../../../shared/json/json-property";

@JsonObject()
export class InboxKey extends ContainerKey {

  static readonly DEFAULT = new InboxKey();

  @JsonProperty()
  membersKey: MembersKey;

  static from(id1: string, id2: string) {
    return new InboxKey(id1, id2);
  }

  constructor(...parts: string[]) {
    super(...parts);
  }
}
