import React, {ReactElement} from "react";
import {StyledBoxColumn} from "../../shared/StyledComponents";
import {DIVIDER, PD_LG, PD_XLG} from "../../shared/dimens";
import {Typography} from "@mui/material";

export function FAIContainer(props: { title: string, text: string, children: any }): ReactElement {
  return <StyledBoxColumn style={{gap: PD_LG}}>
    <StyledBoxColumn style={{padding: PD_XLG, borderBottom: DIVIDER}}>
      <Typography variant="h4">{props.title}</Typography>
      <Typography>{props.text}</Typography>
    </StyledBoxColumn>
    {props.children}
  </StyledBoxColumn>
}