import React, {ReactElement} from "react";
import {Box, ButtonBase, Card, SvgIcon, Typography, useMediaQuery, useTheme} from "@mui/material";
import {DIVIDER, PD_MD, PD_XLG, SZ_SM} from "./dimens";
import {Outlet} from "react-router-dom";
import {TabsContainer, TabsContainerProps, TabsContainerState} from "./TabsContainer";
import {PluginHostFragment} from "./PluginHostFragment";
import {BaseApp} from "./BaseApp";
import {StyledBoxRow, StyledSpan} from "./StyledComponents";
import {UserFragment} from "./UserFragment";
import {UserProfilePhoto} from "./types";
import {getMemberAuth} from "./auth";

export type SimpleTabsContainerProps<TAB_TYPE> = TabsContainerProps<TAB_TYPE> & {
  iconHeader?: boolean,
  accessory?: ReactElement,
}

export type SimpleTabsContainerState<TAB_TYPE> = TabsContainerState<TAB_TYPE> & {
  selectedTab: TAB_TYPE,
}

function TabView(props: { tabKey: string, text: string, iconType: typeof SvgIcon, selected?: boolean }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  return <>
    {md
      ? <Typography style={{fontSize: "140%", fontWeight: "bold"}}>{props.text}</Typography>
      : <props.iconType style={{width: 32, height: 32}}/>}
    {props.selected
      ?
      <div style={{position: "absolute", width: "100%", height: 4, bottom: 0, background: theme.palette.primary.main}}/>
      : null}
  </>;
}

function SimpleBar<TAB_TYPE>(props: { tabPath: string, iconHeader?: boolean, } & TabsContainerProps<TAB_TYPE>) {
  const memberAuth = getMemberAuth();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  return <StyledBoxRow style={{
    boxSizing: "border-box",
    flexShrink: 0,
    height: 72,
    paddingLeft: props.iconHeader ? 0 : PD_MD,
    paddingRight: PD_MD,
    gap: PD_XLG,
    borderBottom: DIVIDER,
  }}>
    <img src={!props.iconHeader && md ? "/stamp.png" : "/logo192.png"}
         style={{zIndex: 1, verticalAlign:"top",height: props.iconHeader ? "100%" : 40, alignSelf: "center"}}/>
    {props.tabs.title
      ? <StyledBoxRow style={{alignItems: "center", marginLeft: -12}}>
        <Typography variant="h5">{props.tabs.title}</Typography>
      </StyledBoxRow>
      : null}
    {TabsContainer.expandGroups(props.tabs).items.filter(tab => !tab.hidden).map(tab => {
      return <ButtonBase
        style={{position: "relative"}}
        onClick={() => props.path.navigate(tab.path)}>
        <TabView tabKey={tab.path} text={tab.text} iconType={tab.iconType} selected={tab.path === props.tabPath}/>
      </ButtonBase>;
    })}
    <StyledSpan/>
    <Card style={{width: SZ_SM, height: SZ_SM, flexShrink: 0, alignSelf: "center"}}>
      <ButtonBase
        onClick={() => BaseApp.CONTEXT.showDialog(
          null,
          () => <UserFragment uid={memberAuth.auth.currentUser.uid}/>)}
      >
        <img src={UserProfilePhoto(memberAuth.member.user)} style={{width: "100%", height: "100%"}}/>
      </ButtonBase>
    </Card>
  </StyledBoxRow>;

}

export abstract class SimpleTabsContainer<TAB_TYPE, P extends SimpleTabsContainerProps<TAB_TYPE>, S extends SimpleTabsContainerState<TAB_TYPE>> extends TabsContainer<TAB_TYPE, P, S> {

  render() {
    return <Box
      style={{display: "flex", flexGrow: 1, flexDirection: "column", position: "relative", overflowX: "hidden"}}>
      <SimpleBar path={this.props.path} iconHeader={this.props.iconHeader} tabs={this.props.tabs}
                 tabPath={this.state.tabPath}/>
      <Outlet/>
      <PluginHostFragment hostId={BaseApp.CONTEXT.getAppConfig().name + "-" + this.props.tabs.containerId}/>
    </Box>
  }
}
