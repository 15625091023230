import React, {ReactElement} from "react";
import {PageWithSidebarContainer} from "shared/PageWithSidebarContainer";
import {SidebarItem, SidebarTab} from "shared/Sidebar";
import {PathComponent, PathProps} from "index";
import {Conversation, Conversations, OnConversationsChangeListener} from "./types";
import {DateUtil} from "shared/date_util";
import {AddOutlined, SmartToyOutlined} from "@mui/icons-material";
import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {NewConversationFragment} from "./NewConversationFragment";
import {StyledBoxRow, StyledSpan} from "shared/StyledComponents";
import {IconButton} from "@mui/material";
import {PD_SM} from "shared/dimens";
import {ConversationFragment} from "./ConversationFragment";

export function ConversationDisplayName(conversation: Conversation) {
  return conversation.displayName || ("Started " + DateUtil.formatDateTime(conversation.created));
}

export type ConversationsFragmentProps = PageFragmentProps & {}

type ConversationsFragmentState = PageFragmentState & {
  sidebarTabs?: SidebarTab[],
}

export class ConversationsFragment extends PageFragment<ConversationsFragmentProps, ConversationsFragmentState> implements OnConversationsChangeListener {

  protected readonly conversations = Conversations.getInstance();

  private updateConversationsListTimeoutId: any;

  protected onCreateState(): ConversationsFragmentState {
    return {
      ...super.onCreateState(),
      // title: "Conversations",
    };
  }

  // createToolbarActions(): Action[] {
  //   return [
  //     new Action("Personas", () => {
  //       this.props.path.navigate("/personas")
  //     }, SmartToyOutlined)
  //   ];
  // }
  //

  componentDidMount() {
    super.componentDidMount();
    this.conversations.registerObserver(this);
    this.updateConversationsListTimeoutId = setInterval(() => {
      this.updateConversationsList(this.conversations.getConversations());
    }, 60_000);
  }

  componentWillUnmount() {
    this.conversations.unregisterObserver(this);
    clearInterval(this.updateConversationsListTimeoutId);
  }

  onConversationAdded(conversation: Conversation) {
  }

  onConversationUpdated(conversation: Conversation) {
    this.updateConversationsList(this.conversations.getConversations());
  }

  protected async fetchOnMount(): Promise<void> {
    const conversations = await this.conversations.getOrLoadConversations();
    this.updateConversationsList(conversations);
  }

  private updateConversationsList(conversations: Conversation[]) {
    this.setState({
      sidebarTabs: [
        {
          id: "conversations",
          title: "All",
          toolbar: <StyledBoxRow
            style={{boxSizing: "border-box", width: "100%", height: 48, padding: PD_SM, alignItems: "center"}}>
            <StyledSpan/>
            <IconButton onClick={() => this.onNewConversation()}>
              <AddOutlined/>
            </IconButton>
          </StyledBoxRow>,
          items: {
            groups: [
              {
                items: [
                  {
                    path: "new",
                    nestedPageWithSidebarBasePath: "chat/conversations",
                    hidden: true,
                    render: () => <NewConversationFragment path={this.props.path}/>,
                  },
                  ...conversations.map(conversation => {
                    return {
                      path: conversation.convoId,
                      nestedPageWithSidebarBasePath: "chat/conversations",
                      iconType: SmartToyOutlined,
                      // iconBackgroundColor: randomPaletteColor(conversation.convoId),
                      title: ConversationDisplayName(conversation),
                      titleSecondary: DateUtil.getDisplayTimeForDiff(conversation.chatThread?.lastMessage?.timestamp / 1000),
                      text: conversation.chatThread?.lastMessage?.text,
                      render: (pathProps: PathProps) => {
                        return <ConversationFragment path={pathProps} conversation={conversation}/>;
                      }
                    } as SidebarItem;
                  })
                ],
              },
            ],
            containerId: "conversations",
          },
        },
      ],
    });
  }

  static nestedPaths(): PathComponent[] {
    return [
      {
        path: "new",
      },
      {
        path: ":conversation_id",
        handle: {containerId: "conversations", path: ":conversation_id"},
      },
    ];
  }

  renderContent(): ReactElement {
    return <PageWithSidebarContainer
      sidebarCollapsible
      title=""
      path={this.props.path}
      sidebarTabs={this.state.sidebarTabs}/>;
  }

  private onNewConversation() {
    this.props.path.navigate("chat/conversations/new");
  }

  protected onNewMeetingInvite() {
  }
}
