// import {BaseMessageHandler, MqttClient} from "shared/mqtt/client";
import {
  ChatCommand,
  ChatMessage,
  ChatMessageState,
  ChatPresence,
  ChatPresenceSubscription,
  ChatProtocolListener,
  ChatSync,
  ChatThread,
  ChatThreadContainerLoader,
  ChatThreadKey,
  ChatTyping
} from "app/shared/messages/chat_protocol";
import {Observable} from "app/shared/messages/util";
import {AppState, OnAppStateChangeListener} from "app/shared/messages/app_state";
import {get, onChildAdded, set} from "@firebase/database";
import {dbRef, dbRef_getVal, dbRef_setVal} from "../../../shared/database";
import {InboxKey} from "./messages";
import {JSON_OBJECT} from "../../../shared/json/helpers";
import {Members, MembersKey} from "../../../shared/entities";
import {getMemberAuth} from "shared/auth";
import {deleteUnusedFields} from "../../../shared/json_util";

export interface OnChatThreadsChangeListener {

  onThreadAdded(thread: ChatThread);

  onThreadUpdated(thread: ChatThread);

  onThreadDeleted(thread: ChatThread);
}

export class ChatThreads extends Observable<OnChatThreadsChangeListener> implements OnChatMessagesChangeListener {

  private static readonly instances: Map<string, ChatThreads> = new Map<string, ChatThreads>();

  static getInstance(membersKey?: MembersKey, inboxKey?: InboxKey) {
    if (!membersKey) {
      membersKey = MembersKey.DEFAULT;
    }
    if (!inboxKey) {
      inboxKey = InboxKey.DEFAULT;
    }
    let instance = this.instances.get(inboxKey.path());
    if (!instance) {
      instance = new ChatThreads(membersKey, inboxKey);
      this.instances.set(inboxKey.path(), instance);
    }
    return instance;
  }

  private static readonly containerLoaders: ChatThreadContainerLoader[] = [];

  static addContainerLoader(loader: ChatThreadContainerLoader) {
    this.containerLoaders.push(loader);
  }

  private readonly threadMap = new Map<string, ChatThread>();

  private sortedThreads: ChatThread[];

  private constructor(readonly membersKey: MembersKey, readonly inboxKey: InboxKey) {
    super();
  }

  onMessageAdded(message: ChatMessage) {
    const chatThread = this.threadMap.get(message.key.id);
    if (chatThread) {
      chatThread.lastMessage = message;
      chatThread.lastMessageId = message.messageId;
      dbRef_setVal("chats/threads" + this.inboxKey.path() + "/" + message.key.id + "/lastMessageId", chatThread.lastMessageId)
        .then(() => this.observers.forEach(observer => observer.onThreadUpdated(chatThread)));
    }
  }

  onMessageDeleted(message: ChatMessage) {
  }

  onMessageUpdated(message: ChatMessage) {
  }

  async loadThreads(): Promise<void> {
    const threadsRef = dbRef("chats/threads" + this.inboxKey.path());
    const result = await get(threadsRef);
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        const thread = JSON_OBJECT.deserializeObject(value, ChatThread);
        await this.add(thread);
      }
    }
    onChildAdded(threadsRef, (result) => {
      const value = result.val();
      const thread = JSON_OBJECT.deserializeObject(value, ChatThread);
      this.add(thread);
    });
  }

  async getOrLoadThreads(): Promise<ChatThread[]> {
    if (!this.sortedThreads) {
      await this.loadThreads();
      this.sortedThreads = Array.from(this.threadMap.values()).sort((t1: ChatThread, t2: ChatThread) => t1.created - t2.created);
    }
    return this.sortedThreads;
  }

  hasThread(key: ChatThreadKey): boolean {
    return this.threadMap.has(key.id);
  }

  async getOrLoadThread(key: ChatThreadKey): Promise<ChatThread> {
    let thread = this.threadMap.get(key.id);
    if (!thread) {
      const value = await dbRef_getVal("chats/threads" + this.inboxKey.path() + "/" + key.id);
      if (value) {
        thread = JSON_OBJECT.deserializeObject(value, ChatThread);
        await this.add(thread);
      }
    }
    return thread;
  }

  getThread(key: ChatThreadKey): ChatThread | undefined {
    return this.threadMap.get(key.id);
  }

  async addThread(thread: ChatThread) {
    if (!this.hasThread(thread.key)) {
      thread.membersKey = this.membersKey?.nonDefaultOrNull();
      thread.inboxKey = this.inboxKey?.nonDefaultOrNull();
      const memberAuth = getMemberAuth(this.membersKey);
      if (thread.creator === memberAuth.member.memberId) {
        const object = JSON_OBJECT.serializeObject(thread);
        await dbRef_setVal("chats/threads" + this.inboxKey.path() + "/" + thread.key.id, object);
        await this.add(thread);
      }
    }
  }

  deleteThread(thread: ChatThread) {
    // this.dbHelper.delete(ThreadsTable.NAME, thread.id);
    // this.cache.delete(thread.id);
    // for (let observer of this.observers) {
    //   observer.onThreadDeleted(thread);
    // }
  }

  private async add(thread: ChatThread) {
    if (!MembersKey.equals(this.membersKey, thread.membersKey)) {
      console.error("Members key mismatch: " + this.membersKey.path() + " !== " + thread.membersKey?.path());
      return;
    }
    if (!InboxKey.equals(this.inboxKey, thread.inboxKey)) {
      console.error("Inbox key mismatch: " + this.inboxKey.path() + " !== " + thread.inboxKey?.path());
      return;
    }
    if (thread.containerRef && !thread.container) {
      for (const loader of ChatThreads.containerLoaders) {
        const container = await loader.loadContainerRef(thread.containerRef);
        if (container) {
          thread.container = container;
          break;
        }
      }
    }
    const memberAuth = getMemberAuth(this.membersKey);
    const myMemberId = memberAuth.member.memberId;
    const myIndex = thread.memberIds().findIndex(memberId => memberId === myMemberId);
    if (myIndex < 0) {
      console.error("Member not a thread participant: " + myMemberId);
      return;
    }
    if (!thread._members) {
      const members = Members.getInstance(this.membersKey);
      thread._members = await Promise.all(
        thread.memberIds()
          .filter(memberId => memberId.indexOf(":") < 0) // Filter out email:<> type member ids
          .map(memberId => members.getOrLoadMember(memberId)));
    }
    if (thread.lastMessageId && !thread.lastMessage) {
      const messages = ChatMessages.getInstance(this.membersKey, this.inboxKey);
      thread.lastMessage = await messages.loadMessage(thread.key, thread.lastMessageId);
    }
    // thread.other = await this.userCache.getUser((await Members.getInstance().getOrLoadMember(thread.participants[1 - myIndex])).uid);
    this.threadMap.set(thread.key.id, thread);
    for (let observer of this.observers) {
      observer.onThreadAdded(thread);
    }
  }
}

export interface OnChatMessagesChangeListener {

  onMessageAdded(message: ChatMessage);

  onMessageUpdated(message: ChatMessage);

  onMessageDeleted(message: ChatMessage);
}

export class ChatMessages extends Observable<OnChatMessagesChangeListener> {

  private static instances: Map<string, ChatMessages> = new Map<string, ChatMessages>();

  static getInstance(membersKey?: MembersKey, inboxKey?: InboxKey) {
    if (!membersKey) {
      membersKey = MembersKey.DEFAULT;
    }
    if (!inboxKey) {
      inboxKey = InboxKey.DEFAULT;
    }
    let instance = this.instances.get(inboxKey.path());
    if (!instance) {
      instance = new ChatMessages(membersKey, inboxKey);
      this.instances.set(inboxKey.path(), instance);
    }
    return instance;
  }

  private readonly memberAuth = getMemberAuth(this.membersKey);
  private readonly threads = ChatThreads.getInstance(this.membersKey, this.inboxKey);
  private readonly messagesMap = new Map<string, ChatMessage[]>();

  private constructor(readonly membersKey: MembersKey, readonly inboxKey: InboxKey) {
    super();
    this.registerObserver(this.threads);
  }

  async loadMessages(key: ChatThreadKey): Promise<void> {
    const messagesRef = dbRef("chats/messages" + this.inboxKey.path() + "/" + key.id);
    const result = await get(messagesRef);
    const messages: ChatMessage[] = [];
    if (result.exists()) {
      let val = result.val();
      for (const key in val) {
        let value = val[key];
        const message = ChatMessage.fromJSON(value); // Don't change fromJSON()
        messages.push(message);
      }
      this.messagesMap.set(key.id, messages);
    }
    onChildAdded(messagesRef, (result) => {
      const value = result.val();
      const message = ChatMessage.fromJSON(value);
      this.addMessage(message);
    });
  }

  async getOrLoadMessages(key: ChatThreadKey): Promise<ChatMessage[]> {
    if (!this.messagesMap.has(key.id)) {
      await this.loadMessages(key);
    }
    return this.messagesMap.get(key.id) || [];
  }

  getMessages(key: ChatThreadKey): ChatMessage[] {
    return this.messagesMap.get(key.id) || [];
  }

  async loadMessage(key: ChatThreadKey, messageId: string): Promise<ChatMessage | null> {
    const value = await dbRef_getVal("chats/messages" + this.inboxKey.path() + "/" + key.id + "/" + messageId);
    if (value) {
      return ChatMessage.fromJSON(value);
    }
    return null;
  }

  async addMessage(message: ChatMessage) {
    if (!this.threads.hasThread(message.key)) {
      let thread = await this.threads.getOrLoadThread(message.key);
      if (!thread) {
        return;
      }
    }
    let messages = this.messagesMap.get(message.key.id);
    if (!messages) {
      messages = [];
      this.messagesMap.set(message.key.id, messages);
    }
    if (messages.find(value => value.messageId === message.messageId ? value : null)) {
    } else {
      messages.push(message);
      if (message.from === this.memberAuth.member.memberId) {
        await this.send(message);
      }
      for (let observer of this.observers) {
        observer.onMessageAdded(message);
      }
    }
    return Promise.resolve();
  }

  deleteMessage(message: ChatMessage) {
    // this.dbHelper.delete(MessagesTable.NAME, message.id);
    // this.cache.delete(message.id);
    // for (let observer of this.observers) {
    //   observer.onMessageDeleted(message);
    // }
  }

  private async send(message: ChatMessage) {
    const object = message.toJSON();
    deleteUnusedFields(message)
    const messagesRef = dbRef("chats/messages" + this.inboxKey.path() + "/" + message.key.id + "/" + message.messageId);
    await set(messagesRef, object);
  }
}

export interface OnChatMessageStatesChangeListener {

  onMessageStatesAdded(...messageStates: ChatMessageState[]);

  onMessageStatesUpdated(...messageStates: ChatMessageState[]);

  onMessageStatesDeleted(...messageStates: ChatMessageState[]);
}

export class ChatMessageStates extends Observable<OnChatMessageStatesChangeListener> {

  private static instance: ChatMessageStates;

  static getInstance() {
    if (!this.instance) {
      this.instance = new ChatMessageStates();
    }
    return this.instance;
  }

  addMessageState(messageState: ChatMessageState) {
    // for (let observer of this.observers) {
    //   observer.onMessageStatesAdded(messageState);
    // }
  }

  send(messageState: ChatMessageState) {
  }
}

export interface OnChatPresenceChangeListener {

  onPresenceSet(...presence: ChatPresence[]);
}

export class ChatPresences extends Observable<OnChatPresenceChangeListener> {

  private static instance: ChatPresences;

  static getInstance() {
    if (!this.instance) {
      this.instance = new ChatPresences();
    }
    return this.instance;
  }

  private readonly cache = new Map<string, ChatPresence>();

  getCachedPresence(username: string): ChatPresence | null {
    return this.cache.get(username);
  }

  setPresence(presence: ChatPresence) {
    // if (presence.username === this.appPrefs.getUsername()) {
    //   this.send(presence);
    // }
    // this.cache.set(presence.username, presence);
    // for (let observer of this.observers) {
    //   observer.onPresenceSet(presence);
    // }
  }

  subscribePresence(presenceSubscription: ChatPresenceSubscription) {
    // console.assert(presenceSubscription.from === this.appPrefs.getUsername());
    // this.mqttClient.send(ENCODER.encode(JSON.stringify(ChatProtocol.encodeSetPresenceSubscriptionIndication(presenceSubscription.from, presenceSubscription.id, presenceSubscription.to, presenceSubscription))));
  }

  send(presence: ChatPresence) {
    // this.mqttClient.send(ENCODER.encode(JSON.stringify(ChatProtocol.encodeSetPresenceIndication(presence.username, presence.id, presence))));
  }
}

export interface OnChatTypingChangeListener {

  onTypingSet(...typing: ChatTyping[]);

  onTypingUnset(...typing: ChatTyping[]);
}

export class ChatTypings extends Observable<OnChatTypingChangeListener> {

  private static instance: ChatTypings;

  static getInstance() {
    if (!this.instance) {
      this.instance = new ChatTypings();
    }
    return this.instance;
  }

  private readonly cache = new Map<string, ChatTyping>();
  private readonly typingTimeoutIds = new Map<string, any>();

  getCachedTyping(username: string): ChatTyping | null {
    return this.cache.get(username);
  }

  clearCachedTyping(username: string) {
    this.cache.delete(username);
  }

  setTyping(typing: ChatTyping) {
    // if (typing.from === this.appPrefs.getUsername()) {
    //   this.send(typing);
    // } else {
    //   let timeoutId = this.typingTimeoutIds.get(typing.from);
    //   if (timeoutId) {
    //     clearTimeout(timeoutId);
    //   }
    //   this.cache.set(typing.from, typing);
    //   this.typingTimeoutIds.set(typing.from, setTimeout(() => {
    //     let removed;
    //     if (removed = this.cache.get(typing.from)) {
    //       this.cache.delete(typing.from);
    //       for (let observer of this.observers) {
    //         observer.onTypingUnset(removed);
    //       }
    //     }
    //   }, TYPING_EXPIRE_DURATION));
    //   for (let observer of this.observers) {
    //     observer.onTypingSet(typing);
    //   }
    // }
  }

  send(typing: ChatTyping) {
    // this.mqttClient.send(ENCODER.encode(JSON.stringify(ChatProtocol.encodeTypingIndication(typing.from, typing.id, typing.to, typing))));
  }
}

export class MessageHandler implements OnAppStateChangeListener {

  private static instance: MessageHandler;

  static getInstance() {
    if (!this.instance) {
      this.instance = new MessageHandler(AppState.getInstance());
    }
    return this.instance;
  }

  private protocolListener: ChatProtocolListener;

  constructor(private readonly appState: AppState) {
    let messages = ChatMessages.getInstance();
    let messageStates = ChatMessageStates.getInstance();
    let presences = ChatPresences.getInstance();
    let typings = ChatTypings.getInstance();
    this.protocolListener = new class implements ChatProtocolListener {
      onChatMessage(message: ChatMessage): boolean {
        messages.addMessage(message);
        return true;
      }

      onChatStateMessage(messageState: ChatMessageState): boolean {
        messageStates.addMessageState(messageState);
        return false;
      }

      onCommandMessage(command: ChatCommand): boolean {
        return false;
      }

      onPresenceSubscriptionIndication(presenceSubscription: ChatPresenceSubscription): boolean {
        throw new Error("Unexpected call.");
      }

      onResultPresenceIndication(presence: ChatPresence): boolean {
        presences.setPresence(presence);
        return false;
      }

      onSetPresenceIndication(presence: ChatPresence): boolean {
        presences.setPresence(presence);
        return false;
      }

      onSyncMessage(sync: ChatSync): boolean {
        return false;
      }

      onTypingIndication(typing: ChatTyping): boolean {
        typings.setTyping(typing);
        return false;
      }
    };

    appState.registerObserver(this);
  }

  onConnectionInit(): void {
    this.maybeSetPresence();
  }

  onAppForegrounded() {
    this.maybeSetPresence();
  }

  onAppBackgrounded() {
    this.maybeUnsetPresence();
  }

  private maybeSetPresence() {
    // if (this.mqttClient.isConnected()) {
    //   let appPrefs = AppPrefs.getInstance();
    //   let presence_ = Presence.getInstance();
    //   let presence = presence_.getCachedPresence(appPrefs.getUsername());
    //   if (!presence || presence.type !== ChatPresence.TYPE_ACTIVE) {
    //     presence_.setPresence(new ChatPresence(appPrefs.getUsername(), appPrefs.getUsername(), Date.now(), ChatPresence.TYPE_ACTIVE));
    //   }
    // }
  }

  private maybeUnsetPresence() {
    // if (this.mqttClient.isConnected()) {
    //   let appPrefs = AppPrefs.getInstance();
    //   let presence_ = Presence.getInstance();
    //   let presence = presence_.getCachedPresence(appPrefs.getUsername());
    //   if (!presence || presence.type !== ChatPresence.TYPE_INACTIVE) {
    //     presence_.setPresence(new ChatPresence(appPrefs.getUsername(), appPrefs.getUsername(), Date.now(), ChatPresence.TYPE_INACTIVE));
    //   }
    // }
  }
}
