import {EMOJI_CODES} from "./emojis";
import {Grid, Popover} from "@mui/material";
import {PD_XSM, PD_XXSM, SZ_SM} from "shared/dimens";
import React from "react";

export type Props = {
  anchorEl?: Element,
  onClose: (event) => void,
}

export class EmojiPopup extends React.PureComponent<Props> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Popover anchorEl={this.props.anchorEl}
                 anchorOrigin={{
                   vertical: 'top',
                   horizontal: 'center',
                 }}
                 transformOrigin={{
                   vertical: 'bottom',
                   horizontal: 'center',
                 }}
                 onClose={(event) => this.props.onClose(event)}
                 open={Boolean(this.props.anchorEl)}
                 keepMounted>
          <div style={{width: 600, height: 720}}>
            <Grid container
                  direction="column"
                  alignItems="center"
                  spacing={1} style={{padding: PD_XSM, flexGrow: 1}}>
              {this.props.anchorEl ? this.renderGrid() : null}
            </Grid>
          </div>
        </Popover>
      </div>
    );

  }

  private renderGrid() {
    let imagesPerRow = 8;
    let imageRows = [];
    let length = EMOJI_CODES.length;
    for (let i = 0; i < length; i += imagesPerRow) {
      imageRows.push(<Grid container
                           item
                           direction="row"
                           spacing={1}>
        {EMOJI_CODES.slice(i, i + Math.min(imagesPerRow, length - i)).map((code) => {
          let url = null;//WebUtil.resourceUrl(this.httpSettings, "emoji/emoji_u" + code + ".png");
          return <Grid item>
            <img src={url}
                 style={{cursor: "pointer", padding: PD_XXSM, width: SZ_SM, height: SZ_SM}}/>
          </Grid>
        })}
      </Grid>);
    }
    return imageRows;
  }

}
