import {BaseFragment, BaseFragmentProps} from "shared/BaseFragment";
import React, {ReactElement} from "react";
import {Conversation} from "./types";
import {StyledContainer, StyledListItem} from "shared/StyledComponents";
import {Action, UserDisplayName, UserProfilePhoto} from "shared/types";

export type ConversationMembersFragmentProps = BaseFragmentProps & {
  conversation: Conversation,
}

export class ConversationMembersFragment extends BaseFragment<ConversationMembersFragmentProps> {

  protected renderContainerContent(): ReactElement | null {
    return <StyledContainer>
      {this.props.conversation.members().map((member, index) => {
        return <StyledListItem
          noLink
          accessory={this.renderAccessoryMore([
            new Action("View profile"),
          ])}
          title={UserDisplayName(member.user)}
          img={UserProfilePhoto(member.user)}
        />;
      })}
    </StyledContainer>;
  }
}
