export abstract class Observable<T> {

  protected observers: T[] = [];

  registerObserver(observer: T) {
    if (this.observers.indexOf(observer) < 0) {
      this.observers.push(observer);
    }
  }

  unregisterObserver(observer: T) {
    this.observers = this.observers.filter(value => value !== observer);
  }
}

const ONE_MINUTE_IN_SECONDS = 60;
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS * 60;
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * 24;
const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7;
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS * 365;

const Util = {
  getDisplayTimeForDiff(timestampInSeconds: number) {
    if (!timestampInSeconds) {
      return undefined;
    }
    let diff = Date.now() / 1000 - timestampInSeconds;
    if (diff < ONE_MINUTE_IN_SECONDS) {
      return 'Just now';
    } else if (diff < ONE_MINUTE_IN_SECONDS * 2) {
      return '1 minute';
    } else if (diff < ONE_HOUR_IN_SECONDS) {
      return `${(diff / 60).toFixed(0)} minutes`;
    } else if (diff < ONE_HOUR_IN_SECONDS * 2) {
      return '1 hour';
    } else if (diff < ONE_HOUR_IN_SECONDS * 10) {
      return `${(diff / (60 * 60)).toFixed(0)} hours`;
    } else {
      let date = new Date();
      date.setTime(timestampInSeconds * 1000);
      return date.toDateString();
    }
  },

  getUrlEncodedString(json) {
    let params = [];
    for (let key in json) {
      let value = json[key];
      if (!value) {
        continue;
      }
      params.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    }
    return params.join("&");
  },

  deleteAllCookies() {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      let eqPos = cookie.indexOf("=");
      let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}

export default Util;
