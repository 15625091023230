import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {Product} from "../products/types";
import React, {ReactElement} from "react";
import {FAIContainer} from "./Components";

export abstract class ProductOverviewFragment<P extends PageFragmentProps = PageFragmentProps, S extends PageFragmentState = PageFragmentState> extends PageFragment<P, S> {

  protected abstract getProduct(): Product;

  renderContent(): React.ReactElement {
    const product = this.getProduct();
    return <FAIContainer title={product.title} text={product.text}>
      {this.renderProductContent()}
    </FAIContainer>;
  }

  protected renderProductContent(): ReactElement {
    return null;
  }
}