import {PageFragment} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {StyledContainer} from "../../shared/StyledComponents";
import {Button, Typography} from "@mui/material";
import {PD_LG, PD_XLG} from "../../shared/dimens";
import {ArticleOutlined, KeyOutlined} from "@mui/icons-material";
import {Action, UserDisplayName} from "../../shared/types";
import {getMemberAuth} from "../../shared/auth";
import {FAIContainer} from "../shared/Components";

export class DashboardFragment extends PageFragment {

  private readonly memberAuth = getMemberAuth();

  private renderButton(action: Action): ReactElement {
    return <Button
      startIcon={<action.iconType style={{fontSize: "200%"}}/>}
      variant="outlined"
      onClick={action.onClick}
      style={{
        justifyContent: "flex-start",
        paddingLeft: PD_XLG,
        paddingRight: PD_XLG,
        paddingTop: PD_LG,
        paddingBottom: PD_LG,
        fontSize: "120%",
      }}>
      {action.text}
    </Button>;
  }

  renderContent(): React.ReactElement {
    return <FAIContainer
      title={`Hello, ${UserDisplayName(this.memberAuth.member.user)}`}
      text={"What would you like to do today?"}>
      <StyledContainer style={{padding: PD_XLG}}>
        <Typography variant="h6">Quick actions</Typography>
        {this.renderButton(new Action("Read the Docs", () => {
          window.open("https://docs.fyne.ai", "_blank");
        }, ArticleOutlined))}
        {this.renderButton(new Action("Get API keys", () => {
          this.props.path.navigate("/settings/api-keys");
        }, KeyOutlined))}
      </StyledContainer>
    </FAIContainer>;
  }
}