import {Observable} from "app/shared/messages/util";
import {get, onChildAdded} from "@firebase/database";
import {dbRef, dbRef_setVal} from "shared/database";
import {JSON_OBJECT} from "shared/json/helpers";
import {JsonObject} from "shared/json/json-object";
import {JsonProperty} from "shared/json/json-property";
import {md5_uuid} from "shared/md5";
import {FormGen} from "shared/formgen";
import {personaAutocompleteLoader} from "../personas/types";
import {Member, Members, MembersKey} from "shared/entities";
import {getMemberAuth} from "shared/auth";
import {InboxKey} from "app/shared/messages/messages";
import {
  ChatThread,
  ChatThreadContainer,
  ChatThreadContainerLoader,
  ChatThreadKey,
  ChatThreadType
} from "app/shared/messages/chat_protocol";
import {ChatThreads, OnChatThreadsChangeListener} from "app/shared/messages/chat_messages";

export interface OnConversationsChangeListener {
  onConversationAdded(conversation: Conversation);

  onConversationUpdated(conversation: Conversation);
}

export class Conversations extends Observable<OnConversationsChangeListener> implements ChatThreadContainerLoader, OnChatThreadsChangeListener {

  private static instance: Conversations;

  static getInstance() {
    if (!this.instance) {
      this.instance = new Conversations();
      ChatThreads.addContainerLoader(this.instance);
    }
    return this.instance;
  }

  private readonly memberAuth = getMemberAuth();

  private unsortedConversations: Conversation[];
  private conversations: Conversation[];

  async loadConversations(): Promise<void> {
    this.unsortedConversations = [];
    const conversationsRef = dbRef("convos/" + this.memberAuth.member.memberId);
    const result = await get(conversationsRef);
    if (result.exists()) {
      const val = result.val();
      for (const key in val) {
        const value = val[key];
        const conversation = JSON_OBJECT.deserializeObject(value, Conversation);
        await this.add(conversation);
      }
    }
    onChildAdded(conversationsRef, (result) => {
      const conversation = JSON_OBJECT.deserializeObject(result.val(), Conversation);
      this.add(conversation);
    });
  }

  onThreadAdded(thread: ChatThread) {
  }

  onThreadDeleted(thread: ChatThread) {
  }

  onThreadUpdated(thread: ChatThread) {
    const conversation = this.unsortedConversations?.find(conversation => conversation.chatThread?.key.id === thread.key.id);
    if (conversation) {
      this.conversations = null;
      this.observers.forEach(observer => observer.onConversationUpdated(conversation));
    }
  }

  async getOrLoadConversations(): Promise<Conversation[]> {
    if (!this.unsortedConversations) {
      await this.loadConversations();
    }
    return Promise.resolve(this.getConversations() || []);
  }

  getUnsortedConversations(): Conversation[] {
    return this.unsortedConversations;
  }

  getConversations(): Conversation[] {
    if (!this.conversations) {
      this.conversations = this.unsortedConversations?.sort((conversation1, conversation2) => (conversation2.chatThread?.lastMessage?.timestamp || 0) - (conversation1.chatThread?.lastMessage?.timestamp || 0));
    }
    return this.conversations;
  }

  async addConversation(conversation: Conversation) {
    const object = JSON_OBJECT.serializeObject(conversation);
    const membersKey = MembersKey.DEFAULT;
    const inboxKey = InboxKey.from(this.memberAuth.member.memberId, conversation.convoId);
    const threadKey = ChatThreadKey.createDefault();
    const refPath = "convos/" + this.memberAuth.member.memberId + "/" + conversation.convoId;
    const chatThread = new ChatThread(threadKey, ChatThreadType.CHAT, null, null, conversation.creator, Date.now(), null, refPath);
    chatThread.container = conversation;
    await ChatThreads.getInstance(membersKey, inboxKey).addThread(chatThread);
    await dbRef_setVal(refPath, object);
    await this.add(conversation);
  }

  deleteConversation(conversation: Conversation) {
    // this.dbHelper.delete(ConversationsTable.NAME, conversation.id);
    // this.cache.delete(conversation.id);
    // for (let observer of this.observers) {
    //   observer.onConversationsDeleted(conversation);
    // }
  }

  async loadContainerRef(containerRef: string): Promise<ChatThreadContainer | null> {
    const conversationsRef = "convos/" + this.memberAuth.member.memberId + "/";
    if (containerRef.startsWith(conversationsRef)) {
      const tokens = containerRef.substring(conversationsRef.length).split("/");
      return (await this.getOrLoadConversations()).find(conversation => conversation.convoId === tokens[0]);
    }
    return null;
  }

  private async add(conversation: Conversation) {
    if (!this.unsortedConversations.find(value => value.convoId === conversation.convoId)) {
      const key = MembersKey.DEFAULT;
      conversation._members = await Promise.all(conversation.memberIds()
        .filter(memberId => memberId.indexOf(":") < 0) // Filter out email:<> type member ids
        .map(memberId => Members.getInstance(key).getOrLoadMember(memberId)));
      if (!conversation.members().find(member => member.memberId === this.memberAuth.member.memberId)) {
        return;
      }
      // Add to conversations list first, as loading thread will try to find conversation as parent container.
      this.unsortedConversations.push(conversation);

      // Load associated thread next.
      const inboxKey = InboxKey.from(this.memberAuth.member.memberId, conversation.convoId);
      const threads = ChatThreads.getInstance(key, inboxKey);
      threads.registerObserver(this);
      const thread = await threads.getOrLoadThread(ChatThreadKey.createDefault());
      conversation.chatThread = thread;
      // this.conversations.sort((a, b) => a.chatThread?.)
      for (let observer of this.observers) {
        observer.onConversationAdded(conversation);
      }
    }
  }
}

@JsonObject()
export class Conversation implements ChatThreadContainer {

  // Not serialized.
  _members: Member[];

  chatThread?: ChatThread;

  @JsonProperty()
  convoId: string;

  @JsonProperty()
  created: number;

  @JsonProperty()
  creator: string;

  @FormGen({
    name: "Display name",
    type: "string",
  })
  @JsonProperty()
  displayName: string;

  @FormGen({
    name: "Persona",
    type: "autocomplete",
    autocompleteLoader: personaAutocompleteLoader,
  })
  @JsonProperty()
  personaId: string;

  memberIds(): string[] {
    return [this.creator];
  }

  members(): Member[] {
    return this._members;
  }

  static createNew(creator: string): Conversation {
    return new Conversation(md5_uuid(), Date.now(), creator);
  }

  constructor(convoId: string, created: number, creator: string) {
    this.convoId = convoId;
    this.created = created;
    this.creator = creator;
  }
}
