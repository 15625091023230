import {Auth, getAuth} from "@firebase/auth";
import {Member, Members, MembersKey, User} from "./entities";
import {BaseApp, getGuestMemberId, setGuestMemberId} from "./BaseApp";
import {AppletContext, PluginContext} from "./types";
import {md5_uuid} from "./md5";

export class MemberAuth {
  constructor(readonly member: Member, readonly auth: Auth) {
  }

  getMemberId(): string {
    return this.member?.memberId;
  }
}

const MEMBER_AUTH = new Map<string, MemberAuth>();

function inferKey(key: MembersKey) {
  if (!key) {
    let contextType = BaseApp.CONTEXT.contextType();
    if (contextType === "plugin") {
      const pluginContext = BaseApp.CONTEXT as PluginContext;
      key = pluginContext.getPluginConfig()?.membersKey;
    } else if (contextType === "applet") {
      const appletContext = BaseApp.CONTEXT as AppletContext;
      key = appletContext.getAppletConfig()?.membersKey;
    }
  }
  if (!key) {
    key = MembersKey.DEFAULT;
  }
  return key;
}

export async function initMemberAuth(key?: MembersKey): Promise<MemberAuth> {
  key = inferKey(key);
  const path = key.path();
  let memberAuth = MEMBER_AUTH.get(path);
  if (!memberAuth) {
    const auth = getAuth();
    const member = auth.currentUser && await Members.getInstance(key).getOrLoadMemberByUid(auth.currentUser.uid, true);
    if (member) {
      member.key = key;
      memberAuth = new MemberAuth(member, auth);
      MEMBER_AUTH.set(path, memberAuth);
    }
  }
  return memberAuth;
}

export function getMemberAuth(key?: MembersKey): MemberAuth | null {
  key = inferKey(key);
  const path = key.path();
  const auth = MEMBER_AUTH.get(path);
  if (auth) {
    return auth;
  }
  if (BaseApp.CONTEXT.getAppConfig()?.loginConfig.guestLogin) {
    return getGuestMemberAuth(key);
  }
  return null;
}

export function createGuestMemberAuth(guestMemberId: string, key?: MembersKey) {
  key = inferKey(key);
  const path = key.path();
  const id = "guest:" + path;
  let memberAuth = MEMBER_AUTH.get(id);
  if (!memberAuth) {
    const member = new Member(guestMemberId, null, null);
    member.user = new User(null, "Guest User", null, null, null, null, null, false, false, true);
    member.key = key;
    memberAuth = new MemberAuth(member, null);
    MEMBER_AUTH.set(id, memberAuth);
  }
  return memberAuth;
}

function getGuestMemberAuth(key?: MembersKey): MemberAuth {
  let guestMemberId = getGuestMemberId();
  if (!guestMemberId) {
    guestMemberId = md5_uuid();
    setGuestMemberId(guestMemberId);
  }
  return createGuestMemberAuth(guestMemberId, key);
}
