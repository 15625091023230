import {PageFragment} from "../../shared/PageFragment";
import React, {ReactElement} from "react";
import {PageWithSidebarContainer} from "../../shared/PageWithSidebarContainer";
import {SidebarItems} from "../../shared/Sidebar";
import {PathComponent} from "../../index";
import {gray} from "shared/colors";
import {StyledBoxRow, StyledSpan} from "../../shared/StyledComponents";
import {Button, Typography} from "@mui/material";
import {Product, PRODUCT_CHAT, PRODUCT_GENIMAGE, PRODUCT_STT, PRODUCT_TTS, ProductColor} from "../products/types";
import {PD_SM} from "../../shared/dimens";

function ProductFooter(props: { product: Product }): ReactElement {
  return <StyledBoxRow
    style={{padding: PD_SM, alignItems: "center", borderBottom: ProductColor(props.product) + " 4px solid"}}>
    <StyledSpan/>
    <Typography variant="body2"><a href={props.product.docsLink}>Go to Docs</a></Typography>
    <Button>Unpin</Button>
  </StyledBoxRow>;
}

const PinnedProducts: Product[] = [
  PRODUCT_CHAT,
  PRODUCT_GENIMAGE,
  PRODUCT_STT,
  PRODUCT_TTS,
];

const SIDEBAR_ITEMS: SidebarItems = {
  groups: PinnedProducts.map(product => ({
    title: product.title,
    items: product.pages.map(page => ({
      path: product._id + "/" + page.path,
      title: page.title,
      nestedPaths: page.nestedPaths,
      render: page.render,
    })),
    footer: <ProductFooter product={product}/>,
  })),
  variant: "condensed",
  containerId: "workbench",
};

export class WorkbenchFragment extends PageFragment {

  static nestedPaths(): PathComponent[] {
    return PageWithSidebarContainer.nestedPathsFromSidebarItems(SIDEBAR_ITEMS);
  }

  renderContent(): React.ReactElement {
    return <PageWithSidebarContainer
      path={this.props.path}
      sidebarCollapsible
      sidebarItems={SIDEBAR_ITEMS}
      sidebarBackground={gray}/>;
  }
}