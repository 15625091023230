import {JsonObject} from "../../shared/json/json-object";
import {FormGen} from "../../shared/formgen";
import {JsonProperty} from "../../shared/json/json-property";
import {Type} from "../../shared/json/helpers";
import {$KTM, $KTS, DefaultObjectLoader, KeyMetadatas, KeyTextStrings, TypedObject} from "../../shared/types";
import {getProvisioningId} from "../../shared/BaseApp";

@JsonObject()
export abstract class BaseSettings extends TypedObject {

  constructor() {
    super(null, null);
  }

  protected basePath(): string | null {
    return "settings";
  }

  createDefaultLoader(): DefaultObjectLoader<any> {
    return new DefaultObjectLoader<any>(this.basePath(), this.id, this.getType());
  }
}

@JsonObject()
export class GeneralSettings extends BaseSettings {

  readonly id: string = "general";

  @FormGen({name: "Account name", type: "string", sectionTitle: "Account details"})
  @JsonProperty()
  accountName: string;

  @FormGen({name: "Account ID", type: "string", readonly: true})
  @JsonProperty()
  accountId: string = getProvisioningId();

  @FormGen({
    sectionTitle: "SSL",
    name: "Certificate validation",
    type: "boolean",
    description: "Determines if certificate validation is performed on all FyneAI originated requests."
  })
  @JsonProperty()
  certValidation: boolean = true;

  static async load() {
    const obj = new GeneralSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return GeneralSettings;
  }
}

@JsonObject()
export class NotificationsSettings extends BaseSettings {

  readonly id = "notifications";

  @FormGen({name: "Pricing Updates", description: "Updates to product prices and rates.", type: "boolean"})
  @JsonProperty()
  pricingUpdates: boolean;

  static async load() {
    const obj = new NotificationsSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return NotificationsSettings;
  }
}

export enum Receive2FA {
  TEXT = "text",
  CALL = "call",
}

const RECEIVE_2FA_KTS = new KeyTextStrings([
  $KTS(Receive2FA.TEXT, "Text"),
  $KTS(Receive2FA.CALL, "Call"),
])

@JsonObject()
export class AccountSecuritySettings extends BaseSettings {

  readonly id = "account_security";

  @FormGen({
    name: "Receive 2FA code",
    description: "How do you want to receive your 2FA code.",
    type: "enum",
    enumValues: RECEIVE_2FA_KTS.values,
    enumDefaultKey: Receive2FA.TEXT
  })
  @JsonProperty()
  receive2FA: Receive2FA = Receive2FA.TEXT;

  static async load() {
    const obj = new AccountSecuritySettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return AccountSecuritySettings;
  }
}

export enum FontSize {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

const FONT_SIZE_KTS = new KeyTextStrings([
  $KTS(FontSize.SMALL, "Small"),
  $KTS(FontSize.DEFAULT, "Default"),
  $KTS(FontSize.LARGE, "Large"),
]);

export enum Timezone {
  AMERICA_LOS_ANGELES = "america_los_angeles",
}

export type TimezoneMetadata = {
  text: string,
  gmt: number,
}

const TIMEZONE_KTM = new KeyMetadatas<TimezoneMetadata>([
  $KTM(Timezone.AMERICA_LOS_ANGELES, {text: "America/Los Angeles", gmt: -8}),
]);

@JsonObject()
export class PreferencesSettings extends BaseSettings {

  readonly id = "preferences";

  @FormGen({
    name: "Timezone",
    description: "Select the timezone you'd like to use to display dates and times.",
    type: "enum",
    enumValues: TIMEZONE_KTM.kts().values,
    enumDefaultKey: Timezone.AMERICA_LOS_ANGELES,
  })
  timezone: string = Timezone.AMERICA_LOS_ANGELES;

  @FormGen({
    name: "Font size",
    sectionTitle: "Display",
    type: "enum",
    description: "Adjust your font size.",
    enumValues: FONT_SIZE_KTS.values,
    enumDefaultKey: FontSize.DEFAULT,
  })
  @JsonProperty()
  fontSize: string;

  static async load() {
    const obj = new PreferencesSettings();
    await obj.load();
    return obj;
  }

  protected getType(): Type<any> {
    return PreferencesSettings;
  }
}

export class Settings {

  private static instance: Settings;

  static getInstance(): Settings {
    return this.instance;
  }

  static async loadInstance() {
    this.instance = new Settings(
      await GeneralSettings.load(),
      await NotificationsSettings.load(),
      await AccountSecuritySettings.load(),
      await PreferencesSettings.load(),
    );
  }

  constructor(
    readonly general: GeneralSettings,
    readonly notifications: NotificationsSettings,
    readonly accountSecurity: AccountSecuritySettings,
    readonly preferences: PreferencesSettings) {
  }
}