// export const FIREBASE_CONFIG = {
//   apiKey: "AIzaSyD4PW-EkfMYF5t-Uq7vft3jreIGksPzCOs",
//   authDomain: "plugins-playground-7971a.firebaseapp.com",
//   projectId: "plugins-playground-7971a",
//   storageBucket: "plugins-playground-7971a.appspot.com",
//   messagingSenderId: "66818322724",
//   appId: "1:66818322724:web:c03ec9054d26ffb40e83b1",
//   measurementId: "G-H0VZ9TTFMY"
// };

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDituqcdpho7Yo9EVzvVFKgJYFphLfJNWc",
  authDomain: "fyneai-apps.firebaseapp.com",
  projectId: "fyneai-apps",
  storageBucket: "fyneai-apps.firebasestorage.app",
  messagingSenderId: "653202191186",
  appId: "1:653202191186:web:12c9baafb94f7b3fb8008b",
  measurementId: "G-5HK6PSY49W"
};

export const CLOUD_URL = "";