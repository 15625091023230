import React, {ReactElement} from "react";
import {SvgIcon} from "@mui/material";
import {PathComponent, PathProps} from "../index";
import {PlaceholderFragment} from "./PlaceholderFragment";
import {User} from "./entities";
import {parseHandle} from "./route_util";
import {PluginFragment} from "./PluginHostFragment";
import {Plugin} from "./plugins";
import {BaseApp} from "./BaseApp";
import {Action, PluginIconType, PluginIconUrl} from "./types";

export type TabInfo<TAB_TYPE> = {
  type: TAB_TYPE,
  path?: string,
  nestedPaths?: PathComponent[],
  text?: string,
  variant?: "tab" | "space",
  iconType?: typeof SvgIcon,
  iconUrl?: string,
  hidden?: boolean,
  fullscreen?: boolean,
  groupType?: "plugins",
  plugins?: Plugin[],
  action?: Action,
  object?: any, // Arbitrary object associated with this tab.
  render?: (path: PathProps) => ReactElement | null,
}

export type Tabs<TAB_TYPE> = {
  title?: string,
  items: TabInfo<TAB_TYPE>[],
  containerId: string,
}

export type TabsContainerProps<TAB_TYPE> = {
  path?: PathProps,
  tabs: Tabs<TAB_TYPE>,
}

export type TabsContainerState<TAB_TYPE> = {
  tabs: TabInfo<TAB_TYPE>[],
  tabPath: string,
  user?: User,
}

export abstract class TabsContainer<TAB_TYPE, P extends TabsContainerProps<TAB_TYPE>, S extends TabsContainerState<TAB_TYPE>> extends React.Component<P, S> {

  protected constructor(props: P, context: any) {
    super(props, context);
    this.state = this.onCreateState();
  }

  protected onCreateState(): S {
    return {
      tabPath: this.props.tabs.items[0].path,
    } as S;
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot?: any) {
    // Nothing.
  }

  getSelectedTab(): TabInfo<TAB_TYPE> {
    return this.props.tabs.items?.find(tab => tab.path === this.state.tabPath);
  }

  static nestedPathsFromTabs(tabs: Tabs<any>): PathComponent[] {
    return tabs.items.map(tab => {
      return {
        path: tab.path,
        handle: {containerId: tabs.containerId, path: tab.path},
        children: tab.nestedPaths,
        render: (pathProps: PathProps) => {
          let rendered;
          if (tab.groupType === "plugins") {
            const pluginInfo = tab.plugins?.find(plugin => plugin.manifest.pluginId === pathProps.params["plugin_id"]);
            if (pluginInfo) {
              rendered = <PluginFragment hostId={BaseApp.CONTEXT.getAppConfig().name + "-" + tabs.containerId}
                                         url={pluginInfo.url}
                                         config={pluginInfo.config}/>;
            }
          } else {
            rendered = tab.render?.(pathProps);
          }
          return rendered ? rendered : <PlaceholderFragment/>;
        },
      } as PathComponent;
    });
  }

  static getDerivedStateFromProps(nextProps: Readonly<TabsContainerProps<any>>, prevState: Readonly<TabsContainerState<any>>) {
    const tabInfo = TabsContainer.selectedTabInfo(TabsContainer.expandGroups(nextProps.tabs), nextProps.path);
    if (prevState.tabPath !== tabInfo?.path) {
      return {
        tabPath: tabInfo.path,
      };
    }
  }

  static expandGroups(tabs: Tabs<any>): Tabs<any> {
    if (!tabs.items?.find(item => item.groupType)) {
      return tabs;
    }
    return {
      ...tabs,
      items: TabsContainer.expandItems(tabs.items),
    };
  }

  private static expandItems(items: TabInfo<any>[]): TabInfo<any>[] {
    const expanded: TabInfo<any>[] = [];
    for (const item of items) {
      if (item.groupType === "plugins") {
        for (const plugin of item.plugins) {
          const iconUrl = PluginIconUrl(plugin.manifest);
          expanded.push({
            type: plugin.manifest.pluginId,
            path: plugin.manifest.pluginId,
            text: plugin.manifest.name,
            iconType: !iconUrl && PluginIconType(plugin.manifest),
            iconUrl: iconUrl,
          } as TabInfo<any>)
        }
      } else {
        expanded.push(item);
      }
    }
    return expanded;
  }

  private static selectedTabInfo(tabs: Tabs<any>, pathProps: PathProps): TabInfo<any> {
    const handle = pathProps?.matches.find(match => match.handle?.containerId === tabs.containerId)?.handle;
    let tabInfo = tabs.items.find(value => value.path === handle?.path || value.path === parseHandle(pathProps, handle)?.path);
    if (!tabInfo) {
      tabInfo = tabs.items[0];
    }
    return tabInfo;
  }

  abstract render(): ReactElement;
}
