import {FabSpec, PageFragmentState} from "shared/PageFragment";
import {Add} from "@mui/icons-material";
import {TableDataContainer, TableDataRow, TableDataSource} from "shared/TableDataContainer";
import {TableData} from "shared/tabledata";
import {EditPersonaHelper} from "./EditPersonaFragment";
import {Persona, Personas} from "./types";
import {ReactElement} from "react";
import {TableDataSourceFragmentButtonType, TableDataSourceFragmentProps} from "shared/TableDataSourceFragment";
import {ListTableDataSourceFragment} from "shared/ListTableDataSourceFragment";


class PersonasTableDataRow implements TableDataRow {

  @TableData({name: "Name"})
  name: string;
}

class PersonasTableDataSource implements TableDataSource<Persona, PersonasTableDataRow> {

  createTableDataRow(): PersonasTableDataRow {
    return new PersonasTableDataRow();
  }

  applyTableDataToRow(data: Persona, content: PersonasTableDataRow): void {
    content.name = data.name;
  }

  onTableDataSelected(data: Persona) {
    EditPersonaHelper.editPersona(data);
  }
}

export class PersonasFragment extends ListTableDataSourceFragment<Persona, PersonasTableDataRow, Personas> {

  constructor(props: TableDataSourceFragmentProps, context: any) {
    super(props, context, Personas.getInstance());
  }

  protected onCreateState(): PageFragmentState {
    return {
      ...super.onCreateState(),
    };
  }

  protected getTableToolbarButtonTypes(): TableDataSourceFragmentButtonType[] {
    return [];
  }

  createFabSpec(): FabSpec {
    return {
      iconType: Add,
      variant: 'extended',
      text: "Add new",
      onClick: () => EditPersonaHelper.addPersona(this.memberAuth.member),
    };
  }

  renderContent(): ReactElement {
    return <TableDataContainer
      data={this.loader.getListItems()}
      source={new PersonasTableDataSource()}/>;
  }
}
