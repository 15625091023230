import {SvgIcon} from "@mui/material";
import {HearingOutlined, ImageOutlined, MicNoneOutlined, SmartToyOutlined} from "@mui/icons-material";
import {brightPaletteColors} from "../../shared/colors";
import {md5_code} from "../../shared/md5";
import {SidebarItem} from "../../shared/Sidebar";
import {ChatOverviewFragment} from "./chat/conversations/ChatOverviewFragment";
import {PersonasFragment} from "./chat/personas/PersonasFragment";
import React, {ReactElement} from "react";
import {ConversationsFragment} from "./chat/conversations/ConversationsFragment";
import {GenImageOverviewFragment} from "./genimage/GenImageOverviewFragment";
import {SttOverviewFragment} from "./stt/SttOverviewFragment";
import {TtsOverviewFragment} from "./tts/TtsOverviewFragment";
import {PathComponent, PathProps} from "../../index";

export enum ProductStatus {
  ALPHA = "alpha",
  BETA = "beta",
  PRODUCTION = "production",
  DEPRECATED = "deprecated",
}

export enum ProductGroup {
  COMMUNICATION = "communication",
  AUDIOVISUAL = "audiovisual",
  HEALTH = "health",
}

export type ProductPage = {
  path: string,
  title: string,
  nestedPaths?: PathComponent[],
  render: (pathProps: PathProps) => ReactElement,
}

export type Product = {
  _id: string,
  _color?: string,
  majorVersion: number,
  minorVersion: number,
  buildVersion?: number,
  title: string,
  text: string,
  iconType: typeof SvgIcon,
  status: ProductStatus,
  docsLink: string,
  group: ProductGroup,
  pages: ProductPage[],
}

export function ProductId(product: Product): string {
  return product._id + "-" + product.majorVersion + "." + product.minorVersion + (product.buildVersion ? "." + product.buildVersion : "");
}

export function ProductColor(product: Product) {
  if (!product._color) {
    product._color = brightPaletteColors[Math.abs(md5_code(product.group)) % brightPaletteColors.length];
  }
  return product._color;
}

export const PRODUCT_CHAT: Product = {
  _id: "chat",
  majorVersion: 0,
  minorVersion: 1,
  title: "Chat",
  text: "Create personas and talk to GenAI bots.",
  iconType: SmartToyOutlined,
  status: ProductStatus.ALPHA,
  docsLink: "https://docs.fyne.ai/chat",
  group: ProductGroup.COMMUNICATION,
  pages: [
    {
      path: "overview",
      title: "Overview",
      render: pathProps => <ChatOverviewFragment path={pathProps}/>,
    },
    {
      path: "conversations",
      title: "Conversations",
      nestedPaths: ConversationsFragment.nestedPaths(),
      render: pathProps => <ConversationsFragment path={pathProps}/>,
    },
    {
      path: "personas",
      title: "Personas",
      render: pathProps => <PersonasFragment path={pathProps}/>,
    },
  ],
};

export const PRODUCT_GENIMAGE: Product = {
  _id: "genimage",
  majorVersion: 0,
  minorVersion: 1,
  title: "Generate Image",
  text: "Create images in different styles.",
  iconType: ImageOutlined,
  status: ProductStatus.ALPHA,
  docsLink: "https://docs.fyne.ai/genimage",
  group: ProductGroup.AUDIOVISUAL,
  pages: [
    {
      path: "overview",
      title: "Overview",
      render: pathProps => <GenImageOverviewFragment path={pathProps}/>,
    },
  ],
};

export const PRODUCT_STT: Product = {
  _id: "stt",
  majorVersion: 0,
  minorVersion: 1,
  title: "Speech to Text",
  text: "Convert speech to text.",
  iconType: HearingOutlined,
  status: ProductStatus.ALPHA,
  docsLink: "https://docs.fyne.ai/stt",
  group: ProductGroup.AUDIOVISUAL,
  pages: [
    {
      path: "overview",
      title: "Overview",
      render: pathProps => <SttOverviewFragment path={pathProps}/>,
    },
  ],
};

export const PRODUCT_TTS: Product = {
  _id: "tts",
  majorVersion: 0,
  minorVersion: 1,
  title: "Text to Speech",
  text: "Convert text to speech.",
  iconType: MicNoneOutlined,
  status: ProductStatus.ALPHA,
  docsLink: "https://docs.fyne.ai/tts",
  group: ProductGroup.AUDIOVISUAL,
  pages: [
    {
      path: "overview",
      title: "Overview",
      render: pathProps => <TtsOverviewFragment path={pathProps}/>,
    },
  ],
};

export const PRODUCTS: Product[] = [
  PRODUCT_CHAT,
  PRODUCT_GENIMAGE,
  PRODUCT_STT,
  PRODUCT_TTS,
];