import {ChatFragment, ChatFragmentProps} from "app/shared/messages/ChatFragment";
import {getApp} from "firebase/app";
import {ChatMessage} from "app/shared/messages/chat_protocol";
import {JSON_OBJECT} from "shared/json/helpers";
import {MimeTypeMap} from "shared/mime_types";

export type AppChatFragmentProps = ChatFragmentProps & {
  personaId: string,
}

export class AppChatFragment extends ChatFragment<AppChatFragmentProps> {

  private readonly projectId = getApp().options.projectId;

  onMessageAdded(message: ChatMessage) {
    super.onMessageAdded(message);
    const me = this.memberAuth.member.memberId;
    if (message.from === me) {
      this.doChatCompletion(message);
    }
  }

  private async doChatCompletion(message: ChatMessage) {
    const me = this.memberAuth.member.memberId;
    const body = {
      "inboxKey": JSON_OBJECT.serializeObject(this.props.thread.inboxKey),
      "memberId": me,
      "personaId": this.props.personaId,
      "threadKey": JSON_OBJECT.serializeObject(message.key),
    };
    await fetch("https://invero-chat-dot-plugins-cloud-7971a.uc.r.appspot.com/chat/completions?pid=" + this.projectId, {
      method: "POST",
      headers: {
        "Content-Type": MimeTypeMap.MimeType_application_json,
      },
      body: JSON.stringify(body),
    });
  }
}
