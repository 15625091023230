import {PathComponent} from "index";
import {PageFragmentProps, PageFragmentState} from "../shared/PageFragment";
import React from "react";
import {UserCache} from "../shared/types";
import {getAuth} from "@firebase/auth";
import {User} from "../shared/entities";
import {DashboardFragment} from "./dashboard/DashboardFragment";
import {WorkbenchFragment} from "./workbench/WorkbenchFragment";
import {
  AnalyticsOutlined,
  DesktopWindowsOutlined,
  ExploreOutlined,
  SettingsOutlined,
  SpaceDashboardOutlined
} from "@mui/icons-material";
import {Tabs} from "../shared/TabsContainer";
import {SettingsFragment} from "./settings/SettingsFragment";
import {BaseFragment} from "../shared/BaseFragment";
import {SimpleTabsContainer} from "../shared/SimpleTabsContainer";
import {ExploreFragment} from "./explore/ExploreFragment";
import {MonitorFragment} from "./monitor/MonitorFragment";
import {PlaygroundFragment} from "./playground/PlaygroundFragment";

export type MainProps = PageFragmentProps & {}

type MainState = PageFragmentState & {
  user: User,
}

enum Tab {
  DASHBOARD = "dashboard",
  WORKBENCH = "workbench",
  PLAYGROUND = "playground",
  MONITOR = "monitor",
  EXPLORE = "explore",
  SETTINGS = "settings",
}

const TABS: Tabs<Tab> = {
  items: [
    {
      type: Tab.DASHBOARD,
      path: "dashboard",
      text: "Dashboard",
      iconType: SpaceDashboardOutlined,
      render: path => <DashboardFragment path={path}/>,
    },
    {
      type: Tab.WORKBENCH,
      path: "workbench",
      text: "Workbench",
      iconType: DesktopWindowsOutlined,
      nestedPaths: WorkbenchFragment.nestedPaths(),
      render: path => <WorkbenchFragment path={path}/>,
    },
    {
      type: Tab.PLAYGROUND,
      path: "playground",
      text: "Playground",
      iconType: DesktopWindowsOutlined,
      nestedPaths: PlaygroundFragment.nestedPaths(),
      render: path => <PlaygroundFragment path={path}/>,
    },
    {
      type: Tab.MONITOR,
      path: "monitor",
      text: "Monitor",
      iconType: AnalyticsOutlined,
      nestedPaths: MonitorFragment.nestedPaths(),
      render: path => <MonitorFragment path={path}/>,
    },
    {
      type: Tab.EXPLORE,
      path: "explore",
      text: "Explore",
      iconType: ExploreOutlined,
      nestedPaths: ExploreFragment.nestedPaths(),
      render: path => <ExploreFragment path={path}/>,
    },
    {
      type: Tab.SETTINGS,
      path: "settings",
      text: "Settings",
      iconType: SettingsOutlined,
      nestedPaths: SettingsFragment.nestedPaths(),
      render: path => <SettingsFragment path={path}/>,
    },
  ],
  containerId: "main",
}


export class Main extends BaseFragment<MainProps, MainState> {

  private readonly auth = getAuth();

  static nestedPaths(): PathComponent[] {
    return SimpleTabsContainer.nestedPathsFromTabs(TABS);
  }

  protected async fetchOnMount(forceReload?: boolean): Promise<void> {
    const user = await UserCache.getInstance().getUser(this.auth.currentUser.uid);
    this.setState({
      user: user,
    });
  }

  protected renderContainerContent(): React.ReactElement | null {
    return <SimpleTabsContainer path={this.props.path} tabs={TABS} iconHeader/>;
  }
}
