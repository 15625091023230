export const SECOND_IN_MILLIS = 1000;
export const MINUTE_IN_MILLIS = SECOND_IN_MILLIS * 60;
export const HOUR_IN_MILLIS = MINUTE_IN_MILLIS * 60;
export const DAY_IN_MILLIS = HOUR_IN_MILLIS * 24;
export const WEEK_IN_MILLIS = DAY_IN_MILLIS * 7;

// The following FORMAT_* symbols are used for specifying the format of
// dates and times in the formatDateRange method.
export const FORMAT_SHOW_TIME = 0x00001;
export const FORMAT_SHOW_WEEKDAY = 0x00002;
export const FORMAT_SHOW_YEAR = 0x00004;
export const FORMAT_NO_YEAR = 0x00008;
export const FORMAT_SHOW_DATE = 0x00010;
export const FORMAT_NO_MONTH_DAY = 0x00020;
export const FORMAT_NO_NOON = 0x00200;
export const FORMAT_NO_MIDNIGHT = 0x00800;
export const FORMAT_ABBREV_TIME = 0x04000;
export const FORMAT_ABBREV_WEEKDAY = 0x08000;
export const FORMAT_ABBREV_MONTH = 0x10000;
export const FORMAT_NUMERIC_DATE = 0x20000;
export const FORMAT_ABBREV_RELATIVE = 0x40000;
export const FORMAT_ABBREV_ALL = 0x80000;

export const MONTH_FORMAT = "%B";
export const NUMERIC_MONTH_FORMAT = "%m";
export const MONTH_DAY_FORMAT = "%-d";
export const YEAR_FORMAT = "%Y";
export const YEAR_FORMAT_TWO_DIGITS = "%g";
export const WEEKDAY_FORMAT = "%A";
export const ABBREV_WEEKDAY_FORMAT = "%a";

export class DateUtil {

  static formatDateTime(millis: number, flags: number = 0): string {
    if ((flags & FORMAT_SHOW_YEAR) !== 0) {
      return new Intl.DateTimeFormat("default", {
        day: "numeric",
        month: "short",
        year: "numeric"
      }).format(new Date(millis));
    } else {
      return new Intl.DateTimeFormat("default", {day: "numeric", month: "short"}).format(new Date(millis));
    }
  }
}
