import {PageFragment} from "../../shared/PageFragment";
import React from "react";
import {StyledBoxColumn, StyledBoxRow} from "shared/StyledComponents";
import {Grid, IconButton, Typography} from "@mui/material";
import {DIVIDER, PD_LG, PD_XLG} from "../../shared/dimens";
import {PRODUCTS} from "../products/types";
import {THEME} from "../App";
import {PushPinOutlined} from "@mui/icons-material";
import {FAIContainer} from "../shared/Components";

export class ExploreFragment extends PageFragment {

  renderContent(): React.ReactElement {
    return <FAIContainer
      title={"Explore products"}
      text={"Use this page to explore, add and remove products from your Workbench."}>
      <StyledBoxColumn style={{padding: PD_XLG}}>
        <Grid container spacing={2}>
          {PRODUCTS.map(product => <Grid item xs={12} sm={6} md={4}>
            <StyledBoxRow style={{
              alignItems: "flex-start",
              padding: PD_LG,
              border: DIVIDER,
              gap: PD_LG,
              background: THEME.palette.background.paper
            }}>
              <product.iconType/>
              <StyledBoxColumn style={{flexGrow: 1}}>
                <Typography variant="h6">{product.title}</Typography>
                <Typography>{product.text}</Typography>
                <Typography><a href={product.docsLink}>Docs</a></Typography>
              </StyledBoxColumn>
              <IconButton>
                <PushPinOutlined/>
              </IconButton>
            </StyledBoxRow>
          </Grid>)}
        </Grid>
      </StyledBoxColumn>
    </FAIContainer>;
  }
}