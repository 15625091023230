import React from "react";
import {PageFragment, PageFragmentProps, PageFragmentState} from "shared/PageFragment";
import {StyledBoxColumn, StyledBoxRow, StyledContainer} from "shared/StyledComponents";
import {Button, Typography} from "@mui/material";
import {PD_MD, PD_XLG, PD_XLG2} from "shared/dimens";

type TokBotPersona = {
  id: string,
  displayName: string,
  firstname: string,
  lastname: string,
  profilePhoto: string,
  systemPrompt: string,
}

const PERSONAS: TokBotPersona[] = [
  {
    id: "alfie",
    displayName: "Alfie",
    firstname: "Alfie",
    lastname: "Raysmith",
    profilePhoto: "/images/tokbots/alfie.png",
    systemPrompt: "",
  },
];

export type NewConversationFragmentProps = PageFragmentProps & {}

type NewConversationFragmentState = PageFragmentState & {}

export class NewConversationFragment extends PageFragment<NewConversationFragmentProps, NewConversationFragmentState> {

  renderContent(): React.ReactElement {
    return <StyledContainer>
      <StyledBoxColumn style={{marginTop: PD_XLG2, marginBottom: PD_XLG2, padding: PD_XLG, gap: PD_XLG2}}>
        <StyledBoxColumn>
          <Typography variant="h4">Who would you like to chat with?</Typography>
        </StyledBoxColumn>
        <StyledBoxColumn>
          <Typography variant="h6">Chat with a bot</Typography>
          <StyledBoxColumn>
            {PERSONAS.map(persona => <Button style={{height: 72, padding: 0}}>
              <StyledBoxRow style={{width: "100%", gap: 48, padding: PD_MD, alignItems: "center"}}>
                <img src={persona.profilePhoto}
                     style={{boxSizing: "border-box", flexShrink: 0, width: 56, aspectRatio: 1}}/>
                <StyledBoxColumn style={{flexGrow: 1, alignItems: "flex-start"}}>
                  <Typography variant="h6">{persona.displayName}</Typography>
                </StyledBoxColumn>
              </StyledBoxRow>
            </Button>)}
          </StyledBoxColumn>
        </StyledBoxColumn>
      </StyledBoxColumn>
    </StyledContainer>;
  }
}