import React from "react";
import {Box, Fab, IconButton, TextField} from "@mui/material";
import {PD_MD, PD_SM, SZ_CHAT_BAR} from "shared/dimens";
import {white} from "shared/colors";
import {Attachment, EmojiEmotionsOutlined, SendOutlined} from "@mui/icons-material";
import {EmojiPopup} from "./EmojiPopup";
import {ICON_BUTTON_XSM_STYLE, TYPING_MAX_DELAY} from "shared/constants";
import {Action} from "shared/types";
import {ActionsDialogContent} from "shared/Dialogs";
import {App} from "app/App";

export interface ChatBarListener {
  onSend(text: string): void,

  onTyping(): void,

  onSendMeetingInvite(channel: string): void,
}

type ChatBarProps = {
  flags?: number;
  listener: ChatBarListener,
}

type ChatBarState = {
  text: string;
  showFab: boolean;
  emojiPopupAnchorEl?: any,
  stickerPopupAnchorEl?: any,
}

export class ChatBar extends React.PureComponent<ChatBarProps, ChatBarState> {

  private static readonly MASK_FORMATS = 0xff;
  static readonly FLAG_FORMAT_TEXT = 0x1 << 1;
  static readonly FLAG_FORMAT_EMOJI = 0x1 << 2;

  private static readonly MASK_ATTACHMENTS = 0xff00;
  static readonly FLAG_ATTACHMENT_IMAGE = 0x1 << 8;

  private lastTypingEventTime: number = 0;
  private typingTimeoutId: any;

  constructor(props) {
    super(props);
    this.state = {
      text: "",
      showFab: false,
    };
  }

  render() {
    let showFab = this.state.showFab;
    const flags = this.props.flags || -1;
    const showEmojiButton = (flags & ChatBar.FLAG_FORMAT_EMOJI) != 0;
    const showAttachmentsButton = (flags & ChatBar.MASK_ATTACHMENTS) != 0;
    return (
      <>
        <Box style={{height: SZ_CHAT_BAR, position: "relative"}}>
          <Box display="flex" flexDirection="row" style={{background: white, alignItems: "center"}}>
            {showEmojiButton
              ? <>
                <IconButton onClick={(event) => this.onEmojiButtonClicked(event)}>
                  <EmojiEmotionsOutlined style={ICON_BUTTON_XSM_STYLE}/>
                </IconButton>
                <EmojiPopup anchorEl={this.state.emojiPopupAnchorEl} onClose={() => this.onPopupClosed()}/>
              </>
              : null}
            {/*<IconButton onClick={(event) => this.onStickerButtonClicked(event)}>*/}
            {/*  <img src={iconInsertSticker} style={ICON_BUTTON_SM_STYLE_PD}/>*/}
            {/*</IconButton>*/}
            {/*<StickerPopup anchorEl={this.state.stickerPopupAnchorEl} onClose={() => this.onPopupClosed()}/>*/}
            {/*<IconButton>*/}
            {/*  <Mic style={ICON_BUTTON_SM_STYLE_PD}/>*/}
            {/*</IconButton>*/}
            {/*<IconButton onClick={(event) => this.onSendCallInviteButtonClicked(event)}>*/}
            {/*  <VoiceChatOutlined style={ICON_BUTTON_SM_STYLE_PD}/>*/}
            {/*</IconButton>*/}
            <TextField InputProps={{disableUnderline: true}}
                       autoFocus
                       style={{
                         flexGrow: 1,
                         height: SZ_CHAT_BAR,
                         paddingLeft: PD_SM,
                         paddingRight: PD_SM,
                         minHeight: SZ_CHAT_BAR,
                         display: "flex",
                         justifyContent: "center"
                       }}
                       variant='standard'
                       value={this.state.text}
                       placeholder="Type a message"
                       onKeyDown={event => {
                         if (event.keyCode === 13) {
                           event.preventDefault();
                           this.onSend();
                         }
                       }}
                       onChange={(event) => {
                         let text = event.target.value;
                         let now = Date.now();
                         let delay = Math.max(0, this.lastTypingEventTime <= 0 ? 0 : (this.lastTypingEventTime + TYPING_MAX_DELAY - now));
                         let lastTypingEventTime = now + delay;
                         if (this.typingTimeoutId) {
                           clearTimeout(this.typingTimeoutId);
                           this.typingTimeoutId = null;
                         }
                         this.typingTimeoutId = setTimeout(() => {
                           this.props.listener.onTyping();
                           this.lastTypingEventTime = lastTypingEventTime;
                         }, delay);
                         this.setState({showFab: text.length > 0, text: text});
                       }}
            />
          </Box>
          <Box display='flex' alignItems='center' style={{
            position: 'absolute',
            right: PD_SM,
            bottom: PD_SM,
            gap: PD_SM,
          }}>
            {showAttachmentsButton
              ? <Fab color='default' size='small'>
                <Attachment/>
              </Fab>
              : null}
            <Box visibility={showFab ? 'visible' : 'hidden'} width={showFab ? 'inherit' : '0px'}>
              <Fab color="secondary">
                <SendOutlined onClick={() => this.onSend()}/>
              </Fab>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  private onSend() {
    this.props.listener.onSend(this.state.text);
    this.setState({text: ""});
  }

  private onPopupClosed() {
    this.setState({
      emojiPopupAnchorEl: null,
      stickerPopupAnchorEl: null,
    });
  }

  private onEmojiButtonClicked(event) {
    this.setState({
      emojiPopupAnchorEl: event.target,
    });
  }

  private onStickerButtonClicked(event) {
    this.setState({
      stickerPopupAnchorEl: event.target,
    });
  }

  private onSendCallInviteButtonClicked(event) {
    let actions: Action[] = [
      // new Action("Audio", () => this.onSendCallInvite(ChatPhonecall.CHANNEL_AUDIO)),
      // new Action("Video", () => this.onSendCallInvite(ChatPhonecall.CHANNEL_VIDEO)),
    ];
    App.CONTEXT.showDialog(null, () => <ActionsDialogContent title="Send call invite" actions={actions}/>);
  }

  private onSendCallInvite(channel: string) {
    this.props.listener.onSendMeetingInvite(channel);
  }
}
