import {JsonObject} from "shared/json/json-object";
import {JsonProperty} from "shared/json/json-property";
import {FormGen} from "shared/formgen";
import {md5_uuid} from "shared/md5";
import {JSON_OBJECT} from "shared/json/helpers";
import {$KTS, BaseListItem, BaseListItemsLoader, KeyTextString, UserDisplayName} from "shared/types";
import {Members} from "shared/entities";

export const membersAutocompleteLoader = () => {
  const members = Members.getInstance();
  return new Promise<KeyTextString[]>((resolve, reject) => {
    members.getOrLoadMembers()
      .then(members => resolve(members.map(member => $KTS(member.memberId, UserDisplayName(member.user)))))
      .catch(reason => reject(reason));
  });
}

export const personaAutocompleteLoader = () => {
  const personas = Personas.getInstance();
  return new Promise<KeyTextString[]>((resolve, reject) => {
    // resolve([]);
    personas.getOrLoadListItems()
      .then(personas => resolve(personas.map(persona => $KTS(persona.id, persona.name))))
      .catch(reason => reject(reason));
  })
}

@JsonObject()
export class Persona extends BaseListItem {

  @FormGen({name: "Name", description: "This will be the name your Persona uses in chat", type: "string"})
  @JsonProperty()
  name: string;

  @FormGen({
    name: "Profile photo",
    description: "Upload an image to be used in this Persona's profile",
    type: "profile_photo"
  })
  @JsonProperty()
  profilePhoto: string;

  @FormGen({name: "Short description", type: "string", description: "In just a few words, describe this Persona"})
  @JsonProperty()
  shortDescription: string;

  @FormGen({
    name: "Long description",
    type: "text",
    description: "In a few sentences, describe this Persona. Use unique language and provide many details"
  })
  @JsonProperty()
  longDescription: string;

  static createNew(creator: string): Persona {
    return new Persona(md5_uuid(), creator, Date.now());
  }

  constructor(id: string, creator: string, created: number) {
    super(id, creator, created);
  }
}


export class Personas extends BaseListItemsLoader<Persona> {

  private static instance;

  static getInstance(): Personas {
    if (!this.instance) {
      this.instance = new Personas();
    }
    return this.instance;
  }

  protected basePath(): string {
    return "personas";
  }

  protected deserializeItem(value: any): Persona {
    return JSON_OBJECT.deserializeObject(value, Persona);
  }

  protected serializeItem(item: Persona): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: Persona, item2: Persona): number {
    return item2.created - item1.created;
  }
}
