import {
  ChatMessage,
  ChatSharedMediaItem,
  SHARED_DATA_TYPE_GAME_INVITE,
  SHARED_DATA_TYPE_POST_ITEM,
  SHARED_DATA_TYPE_LOCATION,
  SHARED_DATA_TYPE_MEDIA_ITEM,
  SHARED_DATA_TYPE_PHONECALL_INVITE,
  SHARED_DATA_TYPE_STICKER
} from "./chat_protocol";
import {ChatUtil} from "./chat";
import {MimeTypeMap} from "shared/mime_types";
import {$I18N, $I18N_MAP} from "./i18n";
import {UserCache, UserDisplayName} from "shared/types";
import {Auth, getAuth} from "@firebase/auth";

export class SnippetFactory {

  private static sInstance: SnippetFactory;

  private constructor(private auth: Auth, private readonly userCache: UserCache) {
    $I18N_MAP("me_message_snippet_unknown", "You sent a message");
    $I18N_MAP("me_message_snippet_text", "You: %s");
    $I18N_MAP("me_message_snippet_image", "You sent an image");
    $I18N_MAP("me_message_snippet_audio", "You sent an audio clip");
    $I18N_MAP("me_message_snippet_video", "You sent a video");
    $I18N_MAP("me_message_snippet_other", "You sent a file");
    $I18N_MAP("me_message_snippet_post", "You shared a post");
    $I18N_MAP("me_message_snippet_moment", "You shared a moment");
    $I18N_MAP("me_message_snippet_spotlight", "You shared a spotlight");
    $I18N_MAP("me_message_snippet_sticker", "You sent a sticker");
    $I18N_MAP("me_message_snippet_location", "You sent a location");
    $I18N_MAP("me_message_snippet_phonecall_answered", "You called");
    $I18N_MAP("me_message_snippet_phonecall_not_answered", "Your call was not answered");
    $I18N_MAP("me_message_snippet_game_invite", "You invited %{1} to play %{2}");
    $I18N_MAP("me_message_snippet_game_invite_unknown", "You invited %{1} to play a game");
    $I18N_MAP("me_message_snippet_phonecall_invite_audio", "You invited %{1} to an audio call");
    $I18N_MAP("me_message_snippet_phonecall_invite_video", "You invited %{1} to a video call");

    $I18N_MAP("other_message_snippet_unknown", "%{1} sent you a message");
    $I18N_MAP("other_message_snippet_text", "%{1}: %{2}");
    $I18N_MAP("other_message_snippet_image", "%{1} sent you an image");
    $I18N_MAP("other_message_snippet_audio", "%{1} sent you an audio clip");
    $I18N_MAP("other_message_snippet_video", "%{1} sent you a video");
    $I18N_MAP("other_message_snippet_other", "%{1} sent you a file");
    $I18N_MAP("other_message_snippet_post", "%{1} shared a post");
    $I18N_MAP("other_message_snippet_moment", "%{1} shared a moment");
    $I18N_MAP("other_message_snippet_spotlight", "%{1} shared a spotlight");
    $I18N_MAP("other_message_snippet_sticker", "%{1} sent a sticker");
    $I18N_MAP("other_message_snippet_location", "%{1} sent a location");
    $I18N_MAP("other_message_snippet_phonecall_answered", "%{1} called you");
    $I18N_MAP("other_message_snippet_phonecall_not_answered", "You missed a call from %{1}");
    $I18N_MAP("other_message_snippet_game_invite", "%{1} invited you to play %{2}");
    $I18N_MAP("other_message_snippet_game_invite_unknown", "%{1} invited you to play a game");
    $I18N_MAP("other_message_snippet_phonecall_invite_audio", "%{1} invited you to an audio call");
    $I18N_MAP("other_message_snippet_phonecall_invite_video", "%{1} invited you to an video call");
    $I18N_MAP("me_phonecall_snippet_calling", "Calling %{1}...");
    $I18N_MAP("me_phonecall_snippet_connecting", "Connecting with %{1}...");
    $I18N_MAP("me_phonecall_snippet_connected", "Call with %{1}");
    $I18N_MAP("me_phonecall_snippet_hanging_up", "Hanging up...");

    $I18N_MAP("other_phonecall_snippet_calling", "%{1} is calling you...");
    $I18N_MAP("other_phonecall_snippet_connecting", "Connecting with %{1}...");
    $I18N_MAP("other_phonecall_snippet_connected", "Call with %{1}");
    $I18N_MAP("other_phonecall_snippet_hanging_up", "Hanging up...");
  }

  static getInstance(): SnippetFactory {
    if (this.sInstance == null) {
      this.sInstance = new SnippetFactory(getAuth(), UserCache.getInstance());
    }
    return this.sInstance;
  }

// ****** NOTE ******
// Any changes here MUST make corresponding changes to ChatFragment.getItemViewType()
  getMessageSnippet(message: ChatMessage): string {
    if (message != null && ChatUtil.messageAvailable(message)) {
      const myUsername = this.auth.currentUser.uid;
      const otherDisplayName = UserDisplayName(this.userCache.getCachedUser(message.getOtherUsername(myUsername)));
      if (message.from === myUsername) {
        switch (message.type) {
          case ChatMessage.TYPE_TEXT:
            return $I18N("me_message_snippet_text, Html.fromHtml(message.getText()).toString()");
          case ChatMessage.TYPE_SHARED_DATA_ITEM: {
            const sharedDataItem = message.sharedDataItem;
            switch (sharedDataItem.data.type()) {
              case SHARED_DATA_TYPE_MEDIA_ITEM:
                const mediaItem = ChatSharedMediaItem.fromSharedDataItem(sharedDataItem);
                if (mediaItem != null) {
                  const mimeTypeString = mediaItem.mimeType;
                  if (MimeTypeMap.isImage(mimeTypeString)) {
                    return $I18N("me_message_snippet_image");
                  } else if (MimeTypeMap.isAudio(mimeTypeString)) {
                    return $I18N("me_message_snippet_audio");
                  } else if (MimeTypeMap.isVideo(mimeTypeString)) {
                    return $I18N("me_message_snippet_video");
                  }
                }
                break;
              case SHARED_DATA_TYPE_POST_ITEM:
                // const item = ChatItem.fromSharedDataItem(sharedDataItem);
                // if (item != null) {
                //   if (item.isStoryMoment()) {
                //     return $I18N("me_message_snippet_moment");
                //   } else if (item.isStorySpotlight()) {
                //     return $I18N("me_message_snippet_spotlight");
                //   } else {
                //     return $I18N("me_message_snippet_post");
                //   }
                // }
                break;
              case SHARED_DATA_TYPE_STICKER:
                // const sticker = ChatStickerItem.fromSharedDataItem(sharedDataItem);
                // if (sticker != null) {
                //   return $I18N("me_message_snippet_sticker");
                // }
                break;
              case SHARED_DATA_TYPE_LOCATION:
                // const location = ChatLocation.fromSharedDataItem(sharedDataItem);
                // if (location != null) {
                //   return $I18N("me_message_snippet_location");
                // }
                break;
              case SHARED_DATA_TYPE_GAME_INVITE:
                // const gameInvite = ChatGameInvite.fromSharedDataItem(sharedDataItem);
                // if (gameInvite != null) {
                //   const game = Games.get(gameInvite.key.gameId);
                //   if (game != null) {
                //     return $I18N("me_message_snippet_game_invite", otherDisplayName, game.name);
                //   } else {
                //     return $I18N("me_message_snippet_game_invite_unknown", otherDisplayName);
                //   }
                // }
                break;
              case SHARED_DATA_TYPE_PHONECALL_INVITE:
                // const meetingInvite = ChatMeetingInvite.fromSharedDataItem(sharedDataItem);
                // if (meetingInvite != null) {
                //   return $I18N(ChatPhonecall.CHANNEL_AUDIO === meetingInvite.data.channel
                //     ? "me_message_snippet_phonecall_invite_audio"
                //     : "me_message_snippet_phonecall_invite_video",
                //     otherDisplayName);
                // }
                break;
            }
            return $I18N("me_message_snippet_other");
          }
          case ChatMessage.TYPE_PHONECALL:
            // if (message.phonecall.duration > 0) {
            //   return $I18N("me_message_snippet_phonecall_answered");
            // } else {
            //   return $I18N("me_message_snippet_phonecall_not_answered");
            // }
            break;
        }
        return $I18N("me_message_snippet_unknown");
      } else {
        switch (message.type) {
          case ChatMessage.TYPE_TEXT:
            return $I18N("other_message_snippet_text", otherDisplayName, message.text);
          case ChatMessage.TYPE_SHARED_DATA_ITEM:
            const sharedDataItem = message.sharedDataItem;
            switch (sharedDataItem.data.type()) {
              case SHARED_DATA_TYPE_MEDIA_ITEM:
                const mediaItem = ChatSharedMediaItem.fromSharedDataItem(sharedDataItem);
                if (mediaItem != null) {
                  const mimeTypeString = mediaItem.mimeType;
                  if (MimeTypeMap.isImage(mimeTypeString)) {
                    return $I18N("other_message_snippet_image", otherDisplayName);
                  } else if (MimeTypeMap.isAudio(mimeTypeString)) {
                    return $I18N("other_message_snippet_audio", otherDisplayName);
                  } else if (MimeTypeMap.isVideo(mimeTypeString)) {
                    return $I18N("other_message_snippet_video", otherDisplayName);
                  }
                }
                break;
              case SHARED_DATA_TYPE_POST_ITEM:
                // const item = ChatItem.fromSharedDataItem(sharedDataItem);
                // if (item != null) {
                //   if (item.isStoryMoment()) {
                //     return $I18N("other_message_snippet_moment", otherDisplayName);
                //   } else if (item.isStorySpotlight()) {
                //     return $I18N("other_message_snippet_spotlight", otherDisplayName);
                //   } else {
                //     return $I18N("other_message_snippet_post", otherDisplayName);
                //   }
                // }
                break;
              case SHARED_DATA_TYPE_STICKER:
                // const sticker = ChatStickerItem.fromSharedDataItem(sharedDataItem);
                // if (sticker != null) {
                //   return $I18N("other_message_snippet_sticker", otherDisplayName);
                // }
                break;
              case SHARED_DATA_TYPE_LOCATION:
                // const location = ChatLocation.fromSharedDataItem(sharedDataItem);
                // if (location != null) {
                //   return $I18N("other_message_snippet_location", otherDisplayName);
                // }
                break;
              case SHARED_DATA_TYPE_GAME_INVITE:
                // const gameInvite = ChatGameInvite.fromSharedDataItem(sharedDataItem);
                // if (gameInvite != null) {
                //   const game = Games.get(gameInvite.key.gameId);
                //   if (game != null) {
                //     return $I18N("other_message_snippet_game_invite", otherDisplayName, game.name);
                //   } else {
                //     return $I18N("other_message_snippet_game_invite_unknown", otherDisplayName);
                //   }
                // }
                break;
              case SHARED_DATA_TYPE_PHONECALL_INVITE:
                // const meetingInvite = ChatMeetingInvite.fromSharedDataItem(sharedDataItem);
                // if (meetingInvite != null) {
                //   return $I18N(ChatPhonecall.CHANNEL_AUDIO === meetingInvite.data.channel
                //     ? "other_message_snippet_phonecall_invite_audio"
                //     : "other_message_snippet_phonecall_invite_video",
                //     otherDisplayName);
                // }
                break;
            }
            return $I18N("other_message_snippet_other", otherDisplayName);
          case ChatMessage.TYPE_PHONECALL:
            // if (message.phonecall.duration > 0) {
            //   return $I18N("other_message_snippet_phonecall_answered", otherDisplayName);
            // } else {
            //   return $I18N("other_message_snippet_phonecall_not_answered", otherDisplayName);
            // }
            break;
          case ChatMessage.TYPE_SERVER_NOTE:
            return ""// TODO: Html.fromHtml(message.getServerNote().data);
        }
        return $I18N("other_message_snippet_unknown", otherDisplayName);
      }
    }
    return "";//TODO: ChatUtil.messageUnavailableText(mContext, message);
  }
}
