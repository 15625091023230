import {PageFragment, PageFragmentProps, PageFragmentState} from "../../shared/PageFragment";
import {PageWithSidebarContainer, PageWithSidebarContainerRenderer} from "../../shared/PageWithSidebarContainer";
import React, {ReactElement} from "react";
import {Sidebar, SidebarItem, SidebarItems, SidebarListener} from "../../shared/Sidebar";
import {PathComponent} from "../../index";
import {Button, Grid, TextField, Typography} from "@mui/material";
import {Outlet} from "react-router-dom";
import {
  StyledBoxColumn,
  StyledBoxColumnCard,
  StyledBoxRow,
  StyledContainer,
  StyledSpan
} from "../../shared/StyledComponents";
import {DIVIDER, PD_MD, PD_XLG} from "../../shared/dimens";
import {PlaceholderFragment} from "../../shared/PlaceholderFragment";
import {FormGenContainer} from "../../shared/FormGenContainer";
import {
  AccountSecuritySettings,
  BaseSettings,
  GeneralSettings,
  NotificationsSettings,
  PreferencesSettings,
  Settings
} from "./settings";
import {colorHighlight} from "../../shared/colors";
import Markdown from "react-markdown";
import {Action, EmptyConfig} from "../../shared/types";
import {AddOutlined, AdminPanelSettingsOutlined, CopyAllOutlined, KeyOutlined} from "@mui/icons-material";
import {App} from "../App";
import {ListTableDataSourceFragment} from "../../shared/ListTableDataSourceFragment";
import {AdminKey, AdminKeys} from "../types";
import {TableDataContainer, TableDataRow, TableDataSource} from "../../shared/TableDataContainer";
import {TableData} from "../../shared/tabledata";
import {TableDataSourceFragmentProps} from "../../shared/TableDataSourceFragment";
import {FAIContainer} from "../shared/Components";

abstract class BaseSettingsContentFragment<T extends BaseSettings> extends PageFragment {

  constructor(props: PageFragmentProps, context: any, readonly settings: T) {
    super(props, context);
  }

  renderContent(): React.ReactElement {
    return <StyledContainer>
      <StyledBoxColumn style={{padding: PD_XLG, gap: PD_XLG}}>
        <Typography>{this.getSettingsDescription()}</Typography>
        {this.renderSettingsContent()}
      </StyledBoxColumn>
    </StyledContainer>;
  }

  protected renderSettingsContent(): ReactElement {
    return <FormGenContainer
      autoSave
      onContainerSave={container => this.settings.save()}
      content={this.settings}/>
  }

  protected abstract getSettingsDescription(): string;
}

class GeneralSettingsContentFragment extends BaseSettingsContentFragment<GeneralSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().general);
  }

  protected getTitle(): string | null | undefined {
    return "General settings";
  }

  protected getSettingsDescription(): string {
    return "General account and client settings";
  }
}

class BillingOverviewSettingsFragment extends PageFragment {

  renderContent(): React.ReactElement {
    return <FAIContainer title={"Billing overview"} text={"View & manage your billing information for Fyne AI services."}>
      <StyledBoxColumn style={{padding:PD_XLG}}>
        <StyledBoxColumnCard title={"Credit balance"}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <StyledBoxColumn style={{padding: PD_XLG, alignItems:"center", justifyContent:"center"}}>
                <Typography variant="h4">$0.00</Typography>
                <Typography>Remaining balance</Typography>
              </StyledBoxColumn>
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <StyledBoxColumn style={{padding: PD_XLG}}>
                <Typography variant="h6">Your project is in trial mode.</Typography>
                <Typography>Complete setup to upgrade to a paid account, to access Pro APIs and higher rate limits.</Typography>
                <Button variant="contained">Complete setup</Button>
              </StyledBoxColumn>
            </Grid>
          </Grid>
        </StyledBoxColumnCard>
      </StyledBoxColumn>
    </FAIContainer>
  }
}
class ApiKeysSettingsFragment extends PageFragment {

  private readonly createApiKeyAction: Action = new Action("Create API key", () => {

  }, AddOutlined);

  protected getTitle(): string | null | undefined {
    return "API keys"
  }

  createToolbarActions(): Action[] {
    return [
      this.createApiKeyAction,
    ];
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "Create an API key",
      text: "Create a key to integrate with the Fyne AI's API. You can use the API directly or through a client SDK.",
      iconType: KeyOutlined,
      action: this.createApiKeyAction,
    };
  }

  renderContent(): React.ReactElement {
    return this.renderEmpty();
  }
}

class AdminKeysTableDataRow implements TableDataRow {

  @TableData({name: "Name"})
  name: string;

  @TableData({name: "Key"})
  key: string;
}

class AdminKeysTableDataSource implements TableDataSource<AdminKey, AdminKeysTableDataRow> {

  createTableDataRow(): AdminKeysTableDataRow {
    return new AdminKeysTableDataRow();
  }

  applyTableDataToRow(data: AdminKey, content: AdminKeysTableDataRow): void {
    content.name = data.name;
    content.key = data.key;
  }

  onTableDataSelected(data: AdminKey) {
    //EditAdminKeyHelper.editAdminKey(data);
  }
}

class AdminKeysSettingsFragment extends ListTableDataSourceFragment<AdminKey, AdminKeysTableDataRow, AdminKeys> {

  private static NOTICE: string = "Admin keys allow you to programmatically control organization settings. Create an admin key to manage workspaces, set rate limits, change billing information, and more.\n" +
    "&nbsp;&nbsp;\n\n" +
    "**Be very careful with these keys.** Don't share your admin key with others, or expose it in the browser or other client-side code. Admin keys are owned by the organization and remain active even after the creator is removed.\n"

  private readonly createAdminKeyAction: Action = new Action("Create Admin key", () => {
    App.CONTEXT.showTextInputDialog("Create Admin key", "This is a friendly name for you to identify this key.", "Key name", new Action("Add", (event, keyName: string) => {
      this.hideDialog();
      setTimeout(() => this.onCreateAdminKey(keyName), 200);
    }));
  }, AddOutlined);

  private async onCreateAdminKey(keyName: string) {
    const adminKey = AdminKey.createNew(keyName);
    await this.loader.addListItem(adminKey);
    App.CONTEXT.showCustomDialog("Save Admin key", <StyledBoxColumn>
        <Typography>Keep a record of this admin key. You will not be able to view it again.</Typography>
        <StyledBoxColumn style={{border: DIVIDER, padding: PD_MD}}>
          <TextField size="small" disabled value={adminKey.key}/>
          <StyledBoxRow>
            <StyledSpan/>
            <Button startIcon={<CopyAllOutlined/>}
                    onClick={() => navigator.clipboard.writeText(adminKey.key).then(() => App.CONTEXT.showToast("Copied!"))}>Copy</Button>
          </StyledBoxRow>
        </StyledBoxColumn>
      </StyledBoxColumn>,
      new Action("Done", () => this.hideDialog()));
  }

  constructor(props: TableDataSourceFragmentProps, context: any) {
    super(props, context, AdminKeys.getInstance());
  }

  protected getTitle(): string | null | undefined {
    return "Admin keys";
  }

  createToolbarActions(): Action[] {
    return [
      this.createAdminKeyAction,
    ];
  }

  protected getEmptyConfig(): EmptyConfig {
    return {
      title: "Create an Admin key",
      text: "Create a admin key for access to all of Fyne AI's API.",
      iconType: AdminPanelSettingsOutlined,
      action: this.createAdminKeyAction,
    };
  }

  renderContent(): React.ReactElement {
    const items = this.loader.getListItems();
    return <StyledBoxColumn style={{padding: PD_XLG}}>
      <StyledBoxColumn style={{paddingLeft: PD_XLG, paddingRight: PD_XLG, border: DIVIDER, background: colorHighlight}}>
        <Typography component="div">
          <Markdown>
            {AdminKeysSettingsFragment.NOTICE}
          </Markdown>
        </Typography>
      </StyledBoxColumn>
      {items?.length > 0
        ? <TableDataContainer
          data={items}
          source={new AdminKeysTableDataSource()}/>
        : this.renderEmpty()}
    </StyledBoxColumn>
  }
}

class NotificationsSettingsContentFragment extends BaseSettingsContentFragment<NotificationsSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().notifications);
  }

  protected getTitle(): string | null | undefined {
    return "Notifications";
  }

  protected getSettingsDescription(): string {
    return "Manage which of the following types of notifications you want to receive";
  }
}


export class AccountSecurityFragment extends BaseSettingsContentFragment<AccountSecuritySettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().accountSecurity);
  }

  protected getTitle(): string | null | undefined {
    return "Account security";
  }

  protected getSettingsDescription(): string {
    return "Keep your account safe & secure";
  }
}

export class PreferencesFragment extends BaseSettingsContentFragment<PreferencesSettings> {

  constructor(props: PageFragmentProps, context: any) {
    super(props, context, Settings.getInstance().preferences);
  }

  protected getTitle(): string | null | undefined {
    return "Preferences";
  }

  protected getSettingsDescription(): string {
    return "Manage your console preferences";
  }
}

const SIDEBAR_ITEMS: SidebarItems = {
  groups: [
    {
      items: [
        {
          title: "General",
          path: "general",
          render: pathProps => <GeneralSettingsContentFragment path={pathProps}/>,
        },
        {
          title: "Notifications",
          path: "notifications",
          render: pathProps => <NotificationsSettingsContentFragment path={pathProps}/>,
        },
      ],
      title: "Overview",
      variant: "condensed",
    },
    {
      items: [
        {
          title: "Billing overview",
          path: "billing",
          render: pathProps => <BillingOverviewSettingsFragment path={pathProps}/>,
        },
      ],
      title: "Billing",
      variant: "condensed",
    },
    {
      items: [
        {
          title: "API keys",
          path: "api-keys",
          render: pathProps => <ApiKeysSettingsFragment path={pathProps}/>,
        },
        {
          title: "Admin keys",
          path: "admin-keys",
          render: pathProps => <AdminKeysSettingsFragment path={pathProps}/>,
        },
      ],
      title: "Keys & Tokens",
      variant: "condensed",
    },
    {
      items: [
        {
          title: "Profile",
          path: "profile",
          render: pathProps => <PlaceholderFragment path={pathProps}/>,
        },
        {
          title: "Security",
          path: "security",
          render: pathProps => <AccountSecurityFragment path={pathProps}/>,
        },
        {
          title: "Preferences",
          path: "preferences",
          render: pathProps => <PreferencesFragment path={pathProps}/>,
        },
      ],
      title: "Account",
      variant: "condensed",
    },
  ],
  containerId: "settings",
}

export type SettingsFragmentProps = PageFragmentProps & {}

type SettingsFragmentState = PageFragmentState & {
  selectedItem?: SidebarItem,
}

export class SettingsFragment extends PageFragment<SettingsFragmentProps, SettingsFragmentState> implements PageWithSidebarContainerRenderer, SidebarListener {

  static nestedPaths(): PathComponent[] {
    return PageWithSidebarContainer.nestedPathsFromSidebarItems(SIDEBAR_ITEMS);
  }

  renderContent(): ReactElement {
    return <PageWithSidebarContainer sidebarCollapsible renderer={this}/>;
  }

  renderPageWithSidebarContainerContent(): React.ReactElement {
    return <Outlet/>;
  }

  renderPageWithSidebarContainerSidebar(): React.ReactElement {
    return <Sidebar
      tabs={[{
        id: "default",
        items: SIDEBAR_ITEMS,
        selected: this.state.selectedItem,
        listener: this,
      }]}/>;
  }

  onSelectionChanged(item: SidebarItem) {
    this.setState({selectedItem: item});
  }
}