import React, {ReactElement, RefObject} from "react";
import {ChatBar, ChatBarListener} from "./ChatBar";
import {Box, Divider} from "@mui/material";
import {v4 as uuid} from "uuid";
import {PAGE_FRAGMENT_WIDTH, PD_XLG} from "shared/dimens";
import {ChatItem, ChatView, ChatViewContext, ChatViewListener} from "./ChatView";
import {ChatLocalMediaItem, ChatMessage, ChatPresence, ChatThread, ChatTyping, MessageFactory} from "./chat_protocol";
import {LocalFile} from "shared/types";
import {MimeTypeMap} from "shared/mime_types";
import {
  ChatMessages,
  ChatPresences,
  ChatTypings,
  OnChatMessagesChangeListener,
  OnChatPresenceChangeListener,
  OnChatTypingChangeListener
} from "./chat_messages";
import {PageFragment, PageFragmentProps, PageFragmentState} from "../../../shared/PageFragment";
import {getMemberAuth} from "../../../shared/auth";

export type ChatFragmentProps = PageFragmentProps & {
  thread: ChatThread,
  chatBarFlags?: number,
}

type ChatFragmentState = PageFragmentState & {
  ready?: boolean,
  messages: ChatMessage[],
}

export class ChatFragment<P extends ChatFragmentProps = ChatFragmentProps> extends PageFragment<P, ChatFragmentState> implements OnChatMessagesChangeListener,
  OnChatTypingChangeListener,
  OnChatPresenceChangeListener,
  ChatViewListener,
  ChatBarListener {

  protected readonly memberAuth = getMemberAuth(this.props.thread.membersKey);
  private readonly messages = ChatMessages.getInstance(this.props.thread.membersKey, this.props.thread.inboxKey);
  private readonly presences = ChatPresences.getInstance();
  private readonly typings = ChatTypings.getInstance();

  private readonly chatContainer: RefObject<any>;

  private lastUserGenMessage: ChatMessage;

  constructor(props: P, context: any) {
    super(props, context);
    this.chatContainer = React.createRef();
  }

  protected onCreateState(): ChatFragmentState {
    return {
      ...super.onCreateState(),
      title: this.props.thread.displayName(),
      messages: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.messages.registerObserver(this);
    this.typings.registerObserver(this);
    this.presences.registerObserver(this);

    // this.subscribePresence(true);
  }

  componentWillUnmount() {
    this.messages.unregisterObserver(this);
    this.typings.unregisterObserver(this);
    this.presences.unregisterObserver(this);

    // this.subscribePresence(false);
  }

  componentDidUpdate(prevProps: Readonly<ChatFragmentProps>, prevState: Readonly<ChatFragmentState>, snapshot?: any) {
    if (this.state.messages.length !== prevState.messages.length) {
      let current = this.chatContainer.current;
      if (current) {
        let height = current.getBoundingClientRect().height;
        current.scrollTo(0, height);
      }
    }
    if (this.props.thread.key.id !== prevProps.thread.key.id) {
      this.reload();
    }
  }

  protected async fetchOnMount(): Promise<void> {
    this.setState({
      messages: [],
    });
    const messages = await this.messages.getOrLoadMessages(this.props.thread.key);
    this.setState({
      messages: messages,
    });
  }

  renderContent(): ReactElement {
    if (!this.state.ready) {
      return this.renderPleaseWait();
    }
    let items: ChatItem[] = [];
    let lastDay = 0;
    let lastYear = 0;
    let messages = this.state.messages;
    messages.sort((msg1, msg2) => msg1.timestamp - msg2.timestamp);
    let prevItem: ChatItem = null;
    let item: ChatItem;
    let lastUserGeneratedItem;
    for (let message of messages) {
      if (!message.isUserGenerated(false)) {
        continue;
      }
      let date = new Date(message.timestamp);
      let year = date.getFullYear();
      let day = date.getDay();
      if ((year !== lastYear) || (day !== lastDay)) {
        lastYear = year;
        lastDay = day;
        items.push(item = {
          prevMessage: prevItem?.message,
          message: MessageFactory.createMetaMessage(message.timestamp - 1),
          viewContext: ChatViewContext.THREAD
        });
        if (prevItem) {
          prevItem.nextMessage = item.message;
        }
        prevItem = item;
      }
      items.push(item = {prevMessage: prevItem?.message, message: message, viewContext: ChatViewContext.THREAD});
      if (prevItem) {
        prevItem.nextMessage = item.message;
      }
      prevItem = item;
      lastUserGeneratedItem = item;
    }
    let otherTyping: ChatTyping;
    let otherPresence: ChatPresence;
    if (lastUserGeneratedItem) {
      if (this.onLastMessageChanged(lastUserGeneratedItem.message)) {
        if (lastUserGeneratedItem.message.from !== this.props.thread.myMemberId()) {
          this.typings.clearCachedTyping(lastUserGeneratedItem.message.from);
        } else {
          // scrollToBottom = true;
        }
      }
    }
    // if (otherTyping = this.typings.getCachedTyping(this.props.thread.other.uid)) {
    //   items.push({message: MessageFactory.createTypingMessage(otherTyping), viewContext: ChatViewContext.THREAD});
    // } else if (otherPresence = this.presences.getCachedPresence(this.props.thread.other.uid)) {
    //   items.push({
    //     message: MessageFactory.createPresenceMessage(otherPresence),
    //     viewContext: ChatViewContext.THREAD
    //   });
    // } else {
    //   items.push({
    //     message: MessageFactory.createPresenceMessage(new ChatPresence(this.props.thread.other.uid, "-", 0, ChatPresence.TYPE_HIDDEN)),
    //     viewContext: ChatViewContext.THREAD
    //   });
    // }
    return <Box
      style={{display: "flex", flexDirection: "column", height: 0, flexGrow: 1, overflow: "hidden"}}>
      <Box ref={this.chatContainer} display='flex' flexDirection='column'
           maxWidth={PAGE_FRAGMENT_WIDTH}
           className="hidescroll"
           style={{width: '100%', margin: "auto", overflowY: 'scroll', flexGrow: 1, paddingBottom: PD_XLG}}>
        <span style={{marginBottom: 'auto'}}/>
        {items.map((item, i) => (
          <ChatView membersKey={this.props.thread.membersKey} item={item} chatViewListener={this}/>
        ))}
      </Box>
      <Divider/>
      <ChatBar flags={this.props.chatBarFlags} listener={this}/>
    </Box>;
  }

  private onLastMessageChanged(lastMessage: ChatMessage): boolean {
    if (this.lastUserGenMessage?.messageId === lastMessage.messageId) {
      return false;
    }
    this.lastUserGenMessage = lastMessage;
    return true;
  }

  onMessageAdded(message: ChatMessage) {
    this.setState({
      messages: this.messages.getMessages(message.key),
    });
  }

  onMessageDeleted(message: ChatMessage) {
    this.setState({
      messages: this.messages.getMessages(message.key),
    });
  }

  onMessageUpdated(message: ChatMessage) {
  }

  onPresenceSet(...presence: ChatPresence[]) {
    this.forceUpdate();
  }

  onTypingSet(...typing: ChatTyping[]) {
    this.forceUpdate();
  }

  onTypingUnset(...typing: ChatTyping[]) {
    this.forceUpdate();
  }

  onChatViewMoreClicked(message: ChatMessage): void {
  }

  onChatViewOptionsClicked(message: ChatMessage): void {
  }

  onSend(text: string) {
    const from = this.memberAuth.member.memberId;
    let message = MessageFactory.createTextMessage(from, this.props.thread.key, Date.now(), text);
    this.messages.addMessage(message);
  }

  onComposeGifMessage(url: string) {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        let localMediaItem = ChatLocalMediaItem.fromFile(new LocalFile(blob, "giphy.gif", blob.size), null, uuid(), MimeTypeMap.MimeType_image_gif, "wide", "giphy", "default");
        return null;// this.apiMethods.uploadMediaItemWithProgressDialog(localMediaItem, false);
      }).then(sharedMediaItem => {
      // let myUsername = this.appPrefs.getUsername();
      // let message = MessageFactory.createSharedDataItemMessage(myUsername, this.props.other.uid, this.state.threadKey, Date.now(), sharedMediaItem.toSharedDataItem());
      // this.messages.addMessage(message);
    });
  }

  onTyping() {
    // this.typing.setTyping(new ChatTyping(this.appPrefs.getUsername(), this.state.threadKey.id, this.props.other.uid, Date.now()));
  }

  onSendMeetingInvite(channel: string): void {
    // this.phonecallActionsHelper.invite(this.props.other.uid, this.state.threadKey, channel);
  }
}
