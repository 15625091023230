import {
  ChatMessage,
  ChatSharedMediaItem,
  SHARED_DATA_TYPE_GAME_INVITE,
  SHARED_DATA_TYPE_LOCATION,
  SHARED_DATA_TYPE_MEDIA_ITEM,
  SHARED_DATA_TYPE_PHONECALL_INVITE,
  SHARED_DATA_TYPE_POST_ITEM,
  SHARED_DATA_TYPE_STICKER
} from "app/shared/messages/chat_protocol";
import {SERVER_USERNAME} from "shared/constants";
import {MimeTypeMap} from "shared/mime_types";

export const CHAT_ITEM_CONTAINER_MASK = 0xF << 28;
export const CHAT_ITEM_CONTAINER_MESSAGE = 0 << 28 & CHAT_ITEM_CONTAINER_MASK;
export const CHAT_ITEM_CONTAINER_TRANSPARENT = 1 << 28 & CHAT_ITEM_CONTAINER_MASK;
export const CHAT_ITEM_CONTAINER_MESSAGE_TRANSPARENT = 2 << 28 & CHAT_ITEM_CONTAINER_MASK;
export const CHAT_ITEM_CONTAINER_MESSAGE_STATE = 3 << 28 & CHAT_ITEM_CONTAINER_MASK;

export const CHAT_ITEM_UNAVAILABLE_MASK = 0x3 << 26;
export const CHAT_ITEM_UNAVAILABLE_NONE = 0 << 26; // Available
export const CHAT_ITEM_UNAVAILABLE_UNKNOWN = 1 << 26; // Unknown message type
export const CHAT_ITEM_UNAVAILABLE_EXPIRED = 2 << 26;
export const CHAT_ITEM_UNAVAILABLE_DELETED = 3 << 26;

export const CHAT_ITEM_FROM_MASK = 0xF << 22;
export const CHAT_ITEM_FROM_ME = 1 << 22 & CHAT_ITEM_FROM_MASK;
export const CHAT_ITEM_FROM_OTHER = 2 << 22 & CHAT_ITEM_FROM_MASK;

export const CHAT_ITEM_MESSAGE_MIMETYPE_MASK = 0x3F << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_OTHER = 0 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_IMAGE = 1 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_AUDIO = 2 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_VIDEO = 3 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_STICKER = 4 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_LOCATION = 5 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_STORY_ITEM = 6 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_STORY_MOMENT_ITEM = 7 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_STORY_SPOTLIGHT_ITEM = 8 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_GAME_INVITE = 9 << 16;
export const CHAT_ITEM_MESSAGE_MIMETYPE_PHONECALL_INVITE = 10 << 16;

export const CHAT_ITEM_MESSAGE_TYPE_MASK = 0xFFFF;

export class ChatUtil {

  static getItemViewType(message: ChatMessage, me: string): number {
    let from = 0;
    let type, mimeType, unavailable, container;
    mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_OTHER;
    unavailable = CHAT_ITEM_UNAVAILABLE_NONE;
    switch (message.type) {
      case ChatMessage.TYPE_TEXT:
        container = CHAT_ITEM_CONTAINER_MESSAGE;
        from = message.from === me ? CHAT_ITEM_FROM_ME : CHAT_ITEM_FROM_OTHER;
        unavailable = ChatUtil.messageUnavailable(message);
        break;
      case ChatMessage.TYPE_META:
      case ChatMessage.TYPE_PHONECALL:
      case ChatMessage.TYPE_SERVER_NOTE:
      case ChatMessage.TYPE_OUTDATED:
        container = CHAT_ITEM_CONTAINER_TRANSPARENT;
        unavailable = ChatUtil.messageUnavailable(message);
        break;
      case ChatMessage.TYPE_MESSAGE_STATE:
        container = CHAT_ITEM_CONTAINER_MESSAGE_STATE;
        break;
      case ChatMessage.TYPE_SHARED_DATA_ITEM:
        container = CHAT_ITEM_CONTAINER_MESSAGE;
        from = message.from === me ? CHAT_ITEM_FROM_ME : CHAT_ITEM_FROM_OTHER;
        switch (unavailable = ChatUtil.messageUnavailable(message)) {
          case CHAT_ITEM_UNAVAILABLE_NONE:
            let sharedDataItem = message.sharedDataItem;
            switch (sharedDataItem.data.type()) {
              case SHARED_DATA_TYPE_MEDIA_ITEM:
                let mediaItem = ChatSharedMediaItem.fromSharedDataItem(sharedDataItem);
                if (mediaItem) {
                  let mimeTypeString = mediaItem.mimeType;
                  if (MimeTypeMap.isImage(mimeTypeString)) {
                    mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_IMAGE;
                  } else if (MimeTypeMap.isAudio(mimeTypeString)) {
                    mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_AUDIO;
                  } else if (MimeTypeMap.isVideo(mimeTypeString)) {
                    mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_VIDEO;
                  }
                }
                break;
              case SHARED_DATA_TYPE_STICKER:
                // container = CHAT_ITEM_CONTAINER_MESSAGE_TRANSPARENT;
                // ChatStickerItem sticker = ChatStickerItem.fromSharedDataItem(sharedDataItem);
                // if (sticker) {
                //     mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_STICKER;
                // }
                break;
              case SHARED_DATA_TYPE_LOCATION:
                // let location = ChatLocation.fromSharedDataItem(sharedDataItem);
                // if (location) {
                //     mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_LOCATION;
                // }
                break;
              case SHARED_DATA_TYPE_POST_ITEM:
                // container = CHAT_ITEM_CONTAINER_TRANSPARENT;
                // let item = ChatItem.fromSharedDataItem(sharedDataItem);
                // if (item) {
                //     if (item.isStoryMoment()) {
                //         mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_STORY_MOMENT_ITEM;
                //     } else if (item.isStorySpotlight()) {
                //         mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_STORY_SPOTLIGHT_ITEM;
                //     } else {
                //         mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_STORY_ITEM;
                //     }
                // }
                break;
              case SHARED_DATA_TYPE_GAME_INVITE:
                // let gameInvite = ChatGameInvite.fromSharedDataItem(sharedDataItem);
                // if (gameInvite) {
                //     mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_GAME_INVITE;
                // }
                break;
              case SHARED_DATA_TYPE_PHONECALL_INVITE:
                // let meetingInvite = ChatMeetingInvite.fromSharedDataItem(sharedDataItem);
                // if (meetingInvite) {
                //   mimeType = CHAT_ITEM_MESSAGE_MIMETYPE_PHONECALL_INVITE;
                // }
                break;
            }
            break;
        }
        break;
      case ChatMessage.TYPE_PRESENCE:
      case ChatMessage.TYPE_TYPING:
        container = CHAT_ITEM_CONTAINER_MESSAGE_TRANSPARENT;
        from = message.from === me ? CHAT_ITEM_FROM_ME : CHAT_ITEM_FROM_OTHER;
        break;
      default:
        let fromServer = message.from === SERVER_USERNAME;
        container = fromServer ? CHAT_ITEM_CONTAINER_TRANSPARENT : CHAT_ITEM_CONTAINER_MESSAGE;
        from = fromServer ? 0 : message.from === me ? CHAT_ITEM_FROM_ME : CHAT_ITEM_FROM_OTHER;
        unavailable = CHAT_ITEM_UNAVAILABLE_UNKNOWN;
        break;
    }
    switch (unavailable) {
      case CHAT_ITEM_UNAVAILABLE_NONE:
        type = message.type & CHAT_ITEM_MESSAGE_TYPE_MASK;
        break;
      default:
        switch (container) {
          case CHAT_ITEM_CONTAINER_MESSAGE:
            type = ChatMessage.TYPE_UNAVAILABLE_TEXT;
            break;
          default:
          case CHAT_ITEM_CONTAINER_TRANSPARENT:
            type = ChatMessage.TYPE_UNAVAILABLE_META;
            break;
        }
    }
    return container | unavailable | from | mimeType | type;
  }

  static messageAvailable(message: ChatMessage): boolean {
    return this.messageUnavailable(message) === CHAT_ITEM_UNAVAILABLE_NONE;
  }

  static messageUnavailable(message: ChatMessage): number {
    if (message) {
      if (message.deleted) {
        return CHAT_ITEM_UNAVAILABLE_DELETED;
      } else if (message.isExpired()) {
        return CHAT_ITEM_UNAVAILABLE_EXPIRED;
      }
    }
    return CHAT_ITEM_UNAVAILABLE_NONE;
  }
}
