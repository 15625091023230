import {BASE_STATE, BaseFragment, BaseFragmentProps, BaseFragmentState} from "./BaseFragment";
import React, {ReactElement} from "react";
import {Box, Button, Card, IconButton, TextField, Typography} from "@mui/material";
import {DIVIDER, DW_XS, PD_MD, PD_SM, PD_XLG, PD_XSM, SZ_MD} from "./dimens";
import {UserCache, UserDisplayName, UserProfilePhoto} from "./types";
import {AccountCircleOutlined, EditOutlined, FaceOutlined, LogoutRounded} from "@mui/icons-material";
import {getAuth, signOut} from "@firebase/auth";
import {getDownloadURL, uploadBytes} from "@firebase/storage";
import {BaseApp, clearProvisioningId, DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "./BaseApp";
import {User, UserBuilder} from "./entities";
import {getDatabase, ref, set} from "@firebase/database";
import {JSON_OBJECT} from "./json/helpers";
import {storageRef} from "./storage";
import {StyledBoxColumn, StyledBoxRow} from "./StyledComponents";
import {HtmlFragment} from "./HtmlFragment";
import {lightGray} from "./colors";
import {AboutFragment} from "./AboutFragment";

export type UserFragmentProps = BaseFragmentProps & {
  uid: string,
  inline?: boolean,
}

type EditDisplayName = {
  firstname?: string,
  lastname?: string,
}

type EditProfilePhoto = {
  profilePhoto?: string,
}

type UserFragmentState = BaseFragmentState & {
  user?: User,
  editDisplayName?: EditDisplayName,
  editProfilePhoto?: EditProfilePhoto,
}

export class UserFragment extends BaseFragment<UserFragmentProps, UserFragmentState> {

  private readonly auth = getAuth();

  constructor(props: UserFragmentProps, context: any) {
    super(props, context);
    this.state = {
      ...BASE_STATE,
    };
  }

  protected async fetchOnMount(): Promise<void> {
    const user = await UserCache.getInstance().getUser(this.props.uid);
    this.setState({
      user: user,
    });
  }

  componentDidUpdate(prevProps: Readonly<UserFragmentProps>, prevState: Readonly<UserFragmentState>, snapshot?: any) {
    if (this.state.editProfilePhoto?.profilePhoto && (this.state.user?.profilePhoto !== this.state.editProfilePhoto?.profilePhoto)) {
      const user: User = this.state.user;
      const isMe = this.auth.currentUser?.uid === this.props.uid;
      if (!isMe) {
        return;
      }
      const newUserBuilder = new UserBuilder(user);
      newUserBuilder.profilePhoto = this.state.editProfilePhoto.profilePhoto;
      this.updateUser(newUserBuilder.build());
    }
  }

  protected renderContainerContent(): ReactElement | null {
    const user: User = this.state.user;
    const isMe = this.auth.currentUser?.uid === this.props.uid;
    const onKeyDown = event => {
      if (event.keyCode === 13) {
        event.preventDefault();
        if (!isMe) {
          return;
        }
        const newUserBuilder = new UserBuilder(user);
        newUserBuilder.firstname = this.state.editDisplayName.firstname;
        newUserBuilder.lastname = this.state.editDisplayName.lastname;
        this.updateUser(newUserBuilder.build());
      }
    };
    const changeAvatarEnabled = false;
    const setProfilePhotoEnabled = true;
    return <StyledBoxColumn>
      <StyledBoxColumn style={{padding: PD_MD, width: Math.min(window.innerWidth - 96, DW_XS)}}>
        <Card style={{width: "100%", aspectRatio: 1, alignSelf: "center"}}>
          <img src={UserProfilePhoto(user)}
               style={{width: "100%", height: "100%", objectFit: "cover"}}/>
        </Card>
        <StyledBoxColumn>
          <StyledBoxColumn style={{gap: 0, marginTop: 24, alignItems: "center"}}>
            {!this.state.editDisplayName ? <Box style={{display: "flex", alignItems: "flex-start", gap: PD_SM}}>
                <Typography variant="h4">{UserDisplayName(user)}</Typography>
                {isMe ? <IconButton color="primary" onClick={() => this.setState({
                    editDisplayName: {
                      firstname: user.firstname,
                      lastname: user.lastname,
                    }
                  })}>
                    <EditOutlined/>
                  </IconButton>
                  : null}
              </Box>
              : null}
            {this.state.editDisplayName ? <Box style={{display: "flex", gap: PD_SM}}>
                <TextField
                  size="small"
                  value={this.state.editDisplayName?.firstname} placeholder="Firstname" onKeyDown={onKeyDown}
                  onChange={event => this.setState({
                    editDisplayName: {
                      firstname: event.target.value,
                      lastname: this.state.editDisplayName.lastname
                    }
                  })}/>
                <TextField
                  size="small"
                  value={this.state.editDisplayName?.lastname} placeholder="Lastname" onKeyDown={onKeyDown}
                  onChange={event => this.setState({
                    editDisplayName: {
                      firstname: this.state.editDisplayName.firstname,
                      lastname: event.target.value
                    }
                  })}/>
              </Box>
              : null}
            <Typography>{user.email}</Typography>
          </StyledBoxColumn>
          {this.auth.currentUser?.uid === user.uid ?
            <StyledBoxColumn style={{marginTop: 24}}>
              {changeAvatarEnabled ?
                <Box className="hidescroll" style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 16,
                  height: SZ_MD,
                  overflowX: "scroll",
                  width: 320,
                }}>
                  {this.state.editProfilePhoto
                    ? null
                    : <Button style={{gap: PD_XSM}}
                              onClick={() => this.setState({
                                editProfilePhoto: {
                                  profilePhoto: user.profilePhoto,
                                },
                              })}><FaceOutlined/>Change avatar
                    </Button>}
                </Box>
                : null}
              {setProfilePhotoEnabled
                ? <Button
                  style={{gap: PD_XSM}}
                  onClick={() => {
                    const input = document.createElement('input');
                    input.type = 'file';
                    input.multiple = true;
                    input.accept = "image/*";
                    input.onchange = e => {
                      const file = input.files?.[0];
                      if (file) {
                        const profilePhotoRef = storageRef("users/profile-photos/" + user.uid);
                        uploadBytes(profilePhotoRef, file)
                          .then(snapshot => getDownloadURL(snapshot.ref))
                          .then(url => {
                            this.setState({
                              editProfilePhoto: {
                                profilePhoto: url,
                              }
                            });
                          });
                      }
                    }
                    input.click();
                  }}>
                  <AccountCircleOutlined/>
                  Set profile photo
                </Button>
                : null}
              <Button color="error" style={{gap: PD_XSM}}
                      onClick={() => {
                        signOut(this.auth)
                          .then(() => {
                            clearProvisioningId();
                            window.location.href = "/";
                          });
                      }}><LogoutRounded/>Log out</Button>
            </StyledBoxColumn>
            : null}
        </StyledBoxColumn>
      </StyledBoxColumn>
      {!this.props.inline
        ? <StyledBoxRow style={{
          alignItems: "center",
          background: this.theme.palette.background.default,
          gap: PD_XLG,
          padding: PD_MD,
          borderTop: DIVIDER,
          justifyContent: "center",
          marginTop: PD_MD
        }}>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                 <HtmlFragment
                   url="/privacy.html"/>)}>
              Privacy Policy
            </a>
          </Typography>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog(null, () =>
                 <HtmlFragment
                   url="/terms.html"/>)}>
              Terms
            </a>
          </Typography>
          <Typography variant="caption">
            <a href="#"
               onClick={() => BaseApp.CONTEXT.showDialog({flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK}, () =>
                 <AboutFragment/>)}>
              About
            </a>
          </Typography>
        </StyledBoxRow>
        : null}
    </StyledBoxColumn>;
  }

  private updateUser(user: User) {
    const db = getDatabase();
    set(ref(db, "users/" + this.auth.currentUser?.uid), JSON_OBJECT.serializeObject(user))
      .then((value) => {
        BaseApp.CONTEXT.showToast("Updated.");
      })
      .catch(error => BaseApp.CONTEXT.showToast("Oops. Something went wrong: Update failed."));
    UserCache.getInstance().setUser(user.uid, user);
    this.setState({
      editDisplayName: null,
      editProfilePhoto: null,
    });
  }
}
