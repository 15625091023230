import {DialogFragment, PageFragment, PageFragmentProps} from "shared/PageFragment";
import {Box} from "@mui/material";
import {FormGenContainer} from "shared/FormGenContainer";
import {DW_LG} from "shared/dimens";
import {App} from "app/App";
import {DIALOG_FLAG_DISABLE_BACKDROP_CLICK, DIALOG_FLAG_SHOW_CLOSE} from "shared/BaseApp";
import React from "react";
import {Persona, Personas} from "./types";
import {Member} from "shared/entities";

export class EditPersonaHelper {

  static addPersona(member: Member) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditPersonaFragment member={member} onPersonaSaved={persona => {
        App.CONTEXT.hideDialog();
        App.CONTEXT.showToast("Persona saved.");
      }}/>);
  }

  static editPersona(persona: Persona) {
    App.CONTEXT.showDialog(
      {flags: DIALOG_FLAG_SHOW_CLOSE | DIALOG_FLAG_DISABLE_BACKDROP_CLICK},
      () => <EditPersonaFragment edit={persona} onPersonaSaved={persona => {
        App.CONTEXT.hideDialog();
        App.CONTEXT.showToast("Persona updated.");
      }}/>);
  }
}

export type AddPersonaFragmentProps = PageFragmentProps & {
  edit?: Persona,
  member?: Member,
  onPersonaSaved: (persona: Persona) => void,
}

export class EditPersonaFragment extends DialogFragment<AddPersonaFragmentProps> {

  private readonly personas = Personas.getInstance();
  private readonly persona = this.props.edit ? this.props.edit : Persona.createNew(this.props.member.memberId);

  protected onCreateState() {
    return {
      ...super.onCreateState(),
      title: this.props.edit ? "Edit" : "Add",
    };
  }

  protected styleFlags(): number {
    return PageFragment.STYLE_ACTION_PANEL_TYPE_BUTTON_FLAG;
  }

  protected getActionButtonText(): string {
    return this.props.edit ? "Save" : "Add";
  }

  onActionButtonClicked() {
    this.addPersona()
      .then(() => this.props.onPersonaSaved(this.persona));
  }

  private async addPersona() {
    await this.personas.addListItem(this.persona);
  }

  renderContent(): React.ReactElement | null {
    return <Box style={{display: "flex", width: DW_LG}}>
      <FormGenContainer content={this.persona}/>
    </Box>
  }
}
