import {
  ChatMessage,
  ChatMessageState,
  ChatPresence,
  ChatSharedDataItem,
  ChatSharedMediaItem,
  SHARED_DATA_TYPE_GAME_INVITE,
  SHARED_DATA_TYPE_LOCATION,
  SHARED_DATA_TYPE_MEDIA_ITEM,
  SHARED_DATA_TYPE_PHONECALL_INVITE,
  SHARED_DATA_TYPE_STICKER
} from "app/shared/messages/chat_protocol";
import {Box, Card, IconButton, Typography} from "@mui/material";
import {BORDER_RADIUS, CARD_HORIZONTAL_PADDING, PD_MD, PD_SM, PD_XSM, PD_XXSM, SZ_SM, SZ_XSM} from "shared/dimens";
import React from "react";
import {colorDarkBlue, colorHighlight, gray, translucentGray, translucentWhite} from "shared/colors";
import {DateUtil, FORMAT_SHOW_TIME, FORMAT_SHOW_YEAR} from "./date_util";
import {
  CHAT_ITEM_CONTAINER_MASK,
  CHAT_ITEM_CONTAINER_MESSAGE,
  CHAT_ITEM_CONTAINER_MESSAGE_STATE,
  CHAT_ITEM_CONTAINER_MESSAGE_TRANSPARENT,
  CHAT_ITEM_CONTAINER_TRANSPARENT,
  CHAT_ITEM_FROM_MASK,
  CHAT_ITEM_FROM_ME,
  CHAT_ITEM_FROM_OTHER,
  CHAT_ITEM_MESSAGE_MIMETYPE_IMAGE,
  CHAT_ITEM_MESSAGE_MIMETYPE_MASK,
  CHAT_ITEM_MESSAGE_MIMETYPE_OTHER,
  CHAT_ITEM_MESSAGE_MIMETYPE_PHONECALL_INVITE,
  CHAT_ITEM_MESSAGE_TYPE_MASK,
  CHAT_ITEM_UNAVAILABLE_DELETED,
  CHAT_ITEM_UNAVAILABLE_EXPIRED,
  CHAT_ITEM_UNAVAILABLE_MASK,
  CHAT_ITEM_UNAVAILABLE_NONE,
  CHAT_ITEM_UNAVAILABLE_UNKNOWN,
  ChatUtil
} from "./chat";
import {AVATAR_MD_STYLE, AVATAR_SM_STYLE} from "shared/constants";
import {Text} from "shared/text_util";
import {ExpandMore, MoreVert, VoiceChatOutlined} from "@mui/icons-material";
import {SnippetFactory} from "./snippets";
import {UserCache, UserDisplayName, UserProfilePhoto} from "shared/types";
import {getMemberAuth} from "../../../shared/auth";
import {MembersKey} from "../../../shared/entities";

const PROFILE_IMAGE_STYLE = {...AVATAR_SM_STYLE, flexShrink: 0, marginTop: PD_XSM, marginLeft: CARD_HORIZONTAL_PADDING};

export enum ChatViewContext {
  THREAD,
  PREVIEW,
  QUOTE,
}

export type ChatItem = {
  prevMessage?: ChatMessage,
  message: ChatMessage,
  nextMessage?: ChatMessage,
  viewContext: ChatViewContext,
}

type ChatViewProps = {
  membersKey?: MembersKey,
  item: ChatItem,
  meColor?: string,
  otherColor?: string,
  chatViewListener?: ChatViewListener,
}

type ChatViewState = {
  hover?: boolean,
}

export interface ChatViewListener {
  onChatViewMoreClicked(message: ChatMessage): void,

  onChatViewOptionsClicked(message: ChatMessage): void,
}

export class ChatView extends React.Component<ChatViewProps, ChatViewState> {

  private readonly memberAuth = getMemberAuth(this.props.membersKey);
  private readonly userCache = UserCache.getInstance();

  constructor(props: ChatViewProps, context: any) {
    super(props, context);
    this.state = {};
  }

  render() {
    const prevMessage = this.props.item.prevMessage;
    const message = this.props.item.message;
    const messageId = message.messageId;
    const nextMessage = this.props.item.nextMessage;
    const viewContext = this.props.item.viewContext;
    const meColor = this.props.meColor || "black";
    const otherColor = this.props.otherColor || "white";
    const viewType = ChatUtil.getItemViewType(message, this.memberAuth.member.memberId);
    const messageView = this.renderMessageType(message, viewType);
    let itemView;
    switch (viewType & CHAT_ITEM_CONTAINER_MASK) {
      case CHAT_ITEM_CONTAINER_MESSAGE: {
        switch (viewContext) {
          case ChatViewContext.THREAD:
            let unavailable = false;
            let hasMoreOptions = this.hasMoreOptions(message);
            switch (viewType & CHAT_ITEM_FROM_MASK) {
              case CHAT_ITEM_FROM_ME:
                switch (viewType & CHAT_ITEM_UNAVAILABLE_MASK) {
                  case CHAT_ITEM_UNAVAILABLE_NONE:
                    itemView = (
                      <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start">
                        {hasMoreOptions
                          ? <IconButton
                            style={{padding: PD_SM}}
                            onClick={() => this.props.chatViewListener?.onChatViewMoreClicked(this.props.item.message)}>
                            <MoreVert/>
                          </IconButton>
                          : null}
                        <Card style={{
                          display: "flex",
                          background: meColor,
                          minHeight: SZ_SM,
                          marginTop: PD_XSM,
                          marginRight: CARD_HORIZONTAL_PADDING,
                          position: "relative",
                          cursor: hasMoreOptions ? "pointer" : undefined,
                        }}
                              onMouseEnter={() => this.setState({hover: true})}
                              onMouseLeave={() => this.setState({hover: false})}
                              onClick={() => {
                                if (hasMoreOptions) {
                                  this.props.chatViewListener?.onChatViewMoreClicked(this.props.item.message);
                                }
                              }}>
                          {messageView}
                          <IconButton style={{
                            visibility: this.state.hover ? "visible" : "hidden",
                            background: translucentGray,
                            color: "white",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: SZ_XSM,
                            height: SZ_XSM
                          }} onClick={(event) => {
                            event.stopPropagation();
                            this.props.chatViewListener?.onChatViewOptionsClicked(this.props.item.message)
                          }}>
                            <ExpandMore style={{padding: PD_XXSM}}/>
                          </IconButton>
                        </Card>
                      </Box>
                    );
                    break;
                  case CHAT_ITEM_UNAVAILABLE_UNKNOWN:
                  case CHAT_ITEM_UNAVAILABLE_EXPIRED:
                  case CHAT_ITEM_UNAVAILABLE_DELETED:
                  default:
                    unavailable = true;
                    itemView = (
                      <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        <Card style={{
                          display: "flex",
                          background: meColor,
                          minHeight: SZ_SM,
                          marginTop: PD_XSM,
                          marginRight: CARD_HORIZONTAL_PADDING,
                          opacity: "0.25",
                        }}>
                          {messageView}
                        </Card>
                      </Box>
                    );
                    break;
                }
                break;
              case CHAT_ITEM_FROM_OTHER:
                switch (viewType & CHAT_ITEM_UNAVAILABLE_MASK) {
                  case CHAT_ITEM_UNAVAILABLE_NONE:
                    let profileImageUrl = this.getProfileImageUrl(message, nextMessage);
                    itemView = (
                      <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
                        {
                          profileImageUrl ?
                            <Card style={PROFILE_IMAGE_STYLE}>
                              <img src={profileImageUrl}
                                   style={{width: "100%", height: "100%"}}/>
                            </Card> :
                            <Box style={PROFILE_IMAGE_STYLE}/>
                        }
                        <Card style={{
                          display: "flex",
                          background: otherColor,
                          minHeight: SZ_SM,
                          marginTop: PD_XSM,
                          marginLeft: CARD_HORIZONTAL_PADDING,
                          position: "relative",
                          cursor: hasMoreOptions ? "pointer" : undefined,
                        }}
                              onMouseEnter={() => this.setState({hover: true})}
                              onMouseLeave={() => this.setState({hover: false})}
                              onClick={() => {
                                if (hasMoreOptions) {
                                  this.props.chatViewListener?.onChatViewMoreClicked(this.props.item.message);
                                }
                              }}>
                          {messageView}
                          <IconButton style={{
                            visibility: this.state.hover ? "visible" : "hidden",
                            background: translucentWhite,
                            color: "black",
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: SZ_XSM,
                            height: SZ_XSM
                          }} onClick={(event) => {
                            event.stopPropagation();
                            this.props.chatViewListener?.onChatViewOptionsClicked(this.props.item.message)
                          }}>
                            <ExpandMore style={{padding: PD_XXSM}}/>
                          </IconButton>
                        </Card>
                        {hasMoreOptions
                          ? <IconButton
                            style={{padding: PD_SM}}
                            onClick={() => this.props.chatViewListener?.onChatViewMoreClicked(this.props.item.message)}>
                            <MoreVert/>
                          </IconButton>
                          : null}
                      </Box>
                    );
                    break;
                  case CHAT_ITEM_UNAVAILABLE_UNKNOWN:
                  case CHAT_ITEM_UNAVAILABLE_EXPIRED:
                  case CHAT_ITEM_UNAVAILABLE_DELETED:
                  default:
                    unavailable = true;
                    itemView = (
                      <Box display="flex" flexDirection="row" justifyContent="flex-start">
                        <Card style={{
                          display: "flex",
                          background: `${otherColor}`,
                          opacity: "0.25",
                          minHeight: `${SZ_SM}`,
                          margin: `${PD_XSM}`,
                          alignSelf: "flex-start"
                        }}>
                          {messageView}
                        </Card>
                      </Box>
                    );
                    break;
                }
                break;
              default:
                throw new Error("Unknown view type: " + viewType);
            }
            if (!unavailable) {
              // View
              // quoteView = itemView.findViewById(R.id.quote);
              // quoteViewHolder = new QuoteViewHolder(quoteView, quoteView.findViewById(R.id.quote_attribution_icon), quoteView.findViewById(R.id.quote_attribution_text), quoteView.findViewById(R.id.quote_chat_container));
            }
            break;
          // case ChatViewContext.PREVIEW:
          //     // Unavailable messages should not be shown in preview context.
          //     console.assert((viewType & CHAT_ITEM_UNAVAILABLE_MASK) === CHAT_ITEM_UNAVAILABLE_NONE);
          //     itemView = (
          //         <Card style={{
          //             display: "flex",
          //             background: "white",
          //             minHeight: `${SZ_SM}`,
          //             margin: `${PD_XSM}`,
          //             alignSelf: "stretch"
          //         }}/>
          //     );
          //     itemView = inflater.inflate(R.layout.chat_preview_message_row_view, parent, false);
          //     quoteViewHolder = new QuoteViewHolder(itemView, itemView.findViewById(R.id.quote_attribution_icon), itemView.findViewById(R.id.quote_attribution_text), overrideContainer = itemView.findViewById(R.id.quote_chat_container));
          //     break;
          // case ChatViewContext.QUOTE:
          //     // Unavailable messages should not be shown in quote context.
          //     console.assert((viewType & CHAT_ITEM_UNAVAILABLE_MASK) === CHAT_ITEM_UNAVAILABLE_NONE);
          //     itemView = (
          //         <Card style={{
          //             display: "flex",
          //             background: "white",
          //             minHeight: `${SZ_SM}`,
          //             margin: `${PD_XSM}`,
          //             alignSelf: "stretch"
          //         }}/>
          //     );
          //     itemView = inflater.inflate(R.layout.chat_quote_message_row_view, parent, false);
          //     quoteViewHolder = null;
          //     break;
          default:
            throw new Error("Invalid or unknown context type: " + viewContext);
        }
      }
        break;
      case CHAT_ITEM_CONTAINER_TRANSPARENT: {
        switch (viewContext) {
          case ChatViewContext.THREAD:
            switch (viewType & CHAT_ITEM_UNAVAILABLE_MASK) {
              case CHAT_ITEM_UNAVAILABLE_NONE:
                itemView = (
                  <Box display="flex" flexDirection="row" justifyContent="center">
                    {messageView}
                  </Box>
                );
                break;
              case CHAT_ITEM_UNAVAILABLE_UNKNOWN:
              case CHAT_ITEM_UNAVAILABLE_EXPIRED:
              case CHAT_ITEM_UNAVAILABLE_DELETED:
              default:
                itemView = (
                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                    {messageView}
                  </Box>
                );
                break;
            }
            break;
          default:
            throw new Error("Invalid or unknown context type: " + viewContext);
        }
      }
        break;
      case CHAT_ITEM_CONTAINER_MESSAGE_TRANSPARENT: {
        // Unavailable messages should not be shown in transparent message containers.
        console.assert((viewType & CHAT_ITEM_UNAVAILABLE_MASK) === CHAT_ITEM_UNAVAILABLE_NONE);
        switch (viewContext) {
          case ChatViewContext.THREAD:
            switch (viewType & CHAT_ITEM_FROM_MASK) {
              case CHAT_ITEM_FROM_ME:
                itemView = (
                  <Box display="flex" flexDirection="row" justifyContent="flex-end">
                    {messageView}
                  </Box>
                );
                break;
              case CHAT_ITEM_FROM_OTHER:
                let profileImageUrl = this.getProfileImageUrl(message, nextMessage);
                itemView = (
                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                    {
                      profileImageUrl ?
                        <Card style={PROFILE_IMAGE_STYLE}>
                          <img src={profileImageUrl}
                               style={{width: "100%", height: "100%"}}/>
                        </Card> :
                        <Box style={PROFILE_IMAGE_STYLE}/>
                    }
                    <Box style={{
                      display: "flex",
                      minHeight: `${SZ_SM}`,
                      margin: `${PD_XSM}`,
                    }}>
                      {messageView}
                    </Box>
                  </Box>
                );
                break;
              default:
                throw new Error("Unknown view type: " + viewType);
            }
            // View
            // quoteView = itemView.findViewById(R.id.quote);
            // quoteViewHolder = new QuoteViewHolder(quoteView, quoteView.findViewById(R.id.quote_attribution_icon), quoteView.findViewById(R.id.quote_attribution_text), quoteView.findViewById(R.id.quote_chat_container));
            break;
          // case ChatViewContext.PREVIEW:
          //     itemView = inflater.inflate(R.layout.chat_preview_transparent_row_view, parent, false);
          //     quoteViewHolder = new QuoteViewHolder(itemView, itemView.findViewById(R.id.quote_attribution_icon), itemView.findViewById(R.id.quote_attribution_text), overrideContainer = itemView.findViewById(R.id.quote_chat_container));
          //     break;
          // case ChatViewContext.QUOTE:
          //     itemView = inflater.inflate(R.layout.chat_quote_transparent_row_view, parent, false);
          //     quoteViewHolder = null;
          //     break;
          default:
            throw new Error("Invalid or unknown context type: " + viewContext);
        }
      }
        break;
      case CHAT_ITEM_CONTAINER_MESSAGE_STATE: {
        // Unavailable messages should not be shown in message state containers.
        console.assert((viewType & CHAT_ITEM_UNAVAILABLE_MASK) === CHAT_ITEM_UNAVAILABLE_NONE);
        switch (viewContext) {
          case ChatViewContext.THREAD:
            itemView = (
              <Box display="flex" flexDirection="row" justifyContent="flex-start">
                {messageView}
              </Box>
            );
            break;
          default:
            throw new Error("Invalid or unknown context type: " + viewContext);
        }
      }
        break;
      default:
        throw new Error("Unknown view type: " + viewType);
    }
    return itemView;
  }

  private hasMoreOptions(message: ChatMessage): boolean {
    if (message.type != ChatMessage.TYPE_SHARED_DATA_ITEM) {
      return false;
    }
    let sharedDataItem: ChatSharedDataItem<any>;
    if (!(sharedDataItem = message.sharedDataItem)) {
      return false;
    }
    switch (sharedDataItem.data.type()) {
      case SHARED_DATA_TYPE_MEDIA_ITEM:
      case SHARED_DATA_TYPE_GAME_INVITE:
      case SHARED_DATA_TYPE_PHONECALL_INVITE:
        return true;
      case SHARED_DATA_TYPE_STICKER:
      case SHARED_DATA_TYPE_LOCATION:
      default:
        return false;
    }
  }

  private getDisplayName(prevMessage: ChatMessage, message: ChatMessage): string {
    if (!message.isUserGenerated(true) || prevMessage.isUserGenerated(true) && message.from === prevMessage.from) {
      return null;
    } else {
      const user = this.userCache.getCachedUser(message.from);
      return UserDisplayName(user);
    }
  }

  private getProfileImageUrl(message: ChatMessage, nextMessage?: ChatMessage): string {
    if (!message.isUserGenerated(true) || (nextMessage?.isUserGenerated(true) && message.from === nextMessage?.from)) {
      return null;
    } else {
      const user = this.userCache.getCachedUser(message.from);
      return UserProfilePhoto(user);
    }
  }

  private renderMessageType(message: ChatMessage, viewType: number) {
    switch (viewType & CHAT_ITEM_MESSAGE_TYPE_MASK) {
      case ChatMessage.TYPE_UNAVAILABLE_META:
        return this.renderUnavailableMeta(message, viewType);
      case ChatMessage.TYPE_UNAVAILABLE_TEXT:
        return this.renderUnavailableText(message, viewType);
      case ChatMessage.TYPE_META:
        return this.renderMeta(message, viewType);
      case ChatMessage.TYPE_TEXT:
        return this.renderText(message, viewType);
      case ChatMessage.TYPE_MESSAGE_STATE:
        return this.renderMessageState(message, viewType);
      case ChatMessage.TYPE_SHARED_DATA_ITEM:
        return this.renderSharedDataItem(message, viewType);
      case ChatMessage.TYPE_PRESENCE:
        return this.renderPresence(message, viewType);
      case ChatMessage.TYPE_TYPING:
        return this.renderTyping(message, viewType);
      case ChatMessage.TYPE_PHONECALL:
        return this.renderPhonecall(message, viewType);
      case ChatMessage.TYPE_SERVER_NOTE:
        return this.renderServerNote(message, viewType);
      case ChatMessage.TYPE_OUTDATED:
        return this.renderOutdated(message, viewType);
    }
    return null;
  }

  private renderUnavailableMeta(message: ChatMessage, viewType: number) {
    return (
      <Box>

      </Box>
    );
  }

  private renderUnavailableText(message: ChatMessage, viewType: number) {
    return (
      <Box>

      </Box>
    );
  }

  private renderMeta(message: ChatMessage, viewType: number) {
    return (
      <Card style={{
        display: "flex",
        background: `${colorHighlight}`,
        margin: PD_XSM,
        alignSelf: "center"
      }}>
        <Typography
          variant="caption"
          style={{
            alignSelf: "center",
            paddingTop: PD_XXSM,
            paddingBottom: PD_XXSM,
            paddingLeft: PD_XSM,
            paddingRight: PD_XSM,
          }}>{DateUtil.formatDateTime(Number.parseInt(message.meta.data), FORMAT_SHOW_YEAR)}</Typography>
      </Card>
    );
  }

  private renderText(message: ChatMessage, viewType: number) {
    if (message.from === this.memberAuth.member.memberId) {
      return (
        <Typography
          style={{
            alignSelf: "center",
            paddingTop: PD_XSM,
            paddingBottom: PD_XSM,
            paddingLeft: PD_MD,
            paddingRight: PD_MD,
            color: "white"
          }}>{message.text}</Typography>
      );
    } else {
      return (
        <Typography
          style={{
            alignSelf: "center",
            paddingTop: PD_XSM,
            paddingBottom: PD_XSM,
            paddingLeft: PD_MD,
            paddingRight: PD_MD,
          }}>{message.text}</Typography>
      );
    }
  }

  private renderMessageState(message: ChatMessage, viewType: number) {
    let messageState = message.messageState;
    let text;
    switch (messageState.state) {
      case ChatMessageState.STATE_DELIVERED:
        text = `Delivered • ${DateUtil.formatDateTime(messageState.timestamp, FORMAT_SHOW_TIME)}`;
        break;
      case ChatMessageState.STATE_DELIVERED:
        text = `Read • ${DateUtil.formatDateTime(messageState.timestamp, FORMAT_SHOW_TIME)}`;
        break;
    }
    return (
      <Typography
        style={{alignSelf: "center", padding: `${PD_XSM} ${PD_SM}`}}>{text}</Typography>
    );
  }

  private renderSharedDataItem(message: ChatMessage, viewType: number) {
    const fromMe = (viewType & CHAT_ITEM_FROM_MASK) === CHAT_ITEM_FROM_ME;
    const color = fromMe ? "white" : "black";
    switch (viewType & CHAT_ITEM_MESSAGE_MIMETYPE_MASK) {
      case CHAT_ITEM_MESSAGE_MIMETYPE_OTHER:
        return <Box>
        </Box>;
      case CHAT_ITEM_MESSAGE_MIMETYPE_IMAGE: {
        let sharedMediaItem = ChatSharedMediaItem.fromSharedDataItem(message.sharedDataItem);
        let url = null;//WebUtil.downloadUrl(this.httpSettings, DOWNLOAD_URL_PREFIX + sharedMediaItem.id);
        return <img src={url} style={{objectFit: "cover", width: "216px", height: "216px"}}/>;
      }
      case CHAT_ITEM_MESSAGE_MIMETYPE_PHONECALL_INVITE: {
        //let meetingInvite = ChatMeetingInvite.fromSharedDataItem(message.sharedDataItem);
        return <Box style={{display: "flex", padding: PD_SM, alignItems: "center"}}>
          <Card style={{...AVATAR_MD_STYLE, backgroundColor: colorDarkBlue}}>
            <VoiceChatOutlined style={{
              ...AVATAR_MD_STYLE,
              padding: PD_SM,
              color: "white",
            }}/>
          </Card>
          <Box style={{display: "flex", flexDirection: "column", flexGrow: 1, color: color, marginLeft: PD_SM}}>
            <Typography
              style={{fontWeight: "bold"}}>{SnippetFactory.getInstance().getMessageSnippet(message)}</Typography>
            <Typography>Tap to join</Typography>
          </Box>
        </Box>
      }
    }
    return null;
  }

  private renderPresence(message: ChatMessage, viewType: number) {
    let text;
    switch (message.presence.type) {
      default:
      case ChatPresence.TYPE_HIDDEN:
        text = null;
        break;
      case ChatPresence.TYPE_INACTIVE:
        text = `${UserDisplayName(this.userCache.getCachedUser(message.from))} is offline • ${Text.getTimestampText(message.presence.timestamp).text}`;
        break;
      case ChatPresence.TYPE_ACTIVE:
        text = `${UserDisplayName(this.userCache.getCachedUser(message.from))} is online`;
        break;
    }
    return (
      <Typography variant="body2"
                  style={{alignSelf: "center"}}>{text}</Typography>
    );
  }

  private renderTyping(message: ChatMessage, viewType: number) {
    return (
      <Typography variant="body2"
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold"
                  }}>{`${UserDisplayName(this.userCache.getCachedUser(message.from))} is typing...`}</Typography>
    );
  }

  private renderPhonecall(message: ChatMessage, viewType: number) {
    return (
      <Box>

      </Box>
    );
  }

  private renderServerNote(message: ChatMessage, viewType: number) {
    return (
      <Box>

      </Box>
    );
  }

  private renderOutdated(message: ChatMessage, viewType: number) {
    return (
      <Box style={{
        margin: "auto",
        padding: PD_XSM,
      }}>
        <Box style={{
          background: `${gray}`,
          display: "flex",
          borderRadius: BORDER_RADIUS,
          justifyContent: "center",
          minHeight: `${SZ_SM}`
        }}>
          <Typography variant="body2" style={{alignSelf: "center", padding: `${PD_XSM} ${PD_SM}`}}>Some messages
            were delivered to
            a different device and are not shown here</Typography>
        </Box>
      </Box>
    )
  }
}
