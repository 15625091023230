import {BaseListItem, BaseListItemsLoader} from "../shared/types";
import {JsonObject} from "../shared/json/json-object";
import {JsonProperty} from "../shared/json/json-property";
import {JSON_OBJECT} from "../shared/json/helpers";
import {getMemberAuth} from "../shared/auth";
import {md5_uuid} from "../shared/md5";

@JsonObject()
export class AdminKey extends BaseListItem {

  @JsonProperty()
  readonly name: string;

  @JsonProperty()
  readonly key: string;

  static createNew(name: string): AdminKey {
    return new AdminKey(md5_uuid(), getMemberAuth().getMemberId(), Date.now(), name, "sk-admin-" + md5_uuid() + md5_uuid());
  }

  constructor(id: string, creator: string, created: number, name: string, key: string) {
    super(id, creator, created);
    this.name = name;
    this.key = key;
  }
}

export class AdminKeys extends BaseListItemsLoader<AdminKey> {

  private static instance;

  static getInstance(): AdminKeys {
    if (!this.instance) {
      this.instance = new AdminKeys();
    }
    return this.instance;
  }


  protected basePath(): string {
    return "admin_keys";
  }

  protected deserializeItem(value: any): AdminKey {
    return JSON_OBJECT.deserializeObject(value, AdminKey);
  }

  protected serializeItem(item: AdminKey): any {
    return JSON_OBJECT.serializeObject(item);
  }

  protected sortOrder(item1: AdminKey, item2: AdminKey): number {
    return item1.created - item2.created;
  }
}