import {Observable} from "./util";

export interface OnAppStateChangeListener {

  onAppForegrounded();

  onAppBackgrounded();
}

export class AppState extends Observable<OnAppStateChangeListener> {

  private static instance: AppState;

  static getInstance() {
    if (!this.instance) {
      this.instance = new AppState();
    }
    return this.instance;
  }

  private isForeground: boolean = false;

  onAppResumed() {
    if (this.isForeground) {
      return;
    }
    this.isForeground = true;
    for (let observer of this.observers) {
      observer.onAppForegrounded();
    }
  }

  onAppPaused() {
    if (!this.isForeground) {
      return;
    }
    this.isForeground = false;
    for (let observer of this.observers) {
      observer.onAppForegrounded();
    }
  }
}
