import {v4 as uuid} from "uuid";
import {MimeTypeMap} from "shared/mime_types";
import {BlobType, JSONType, LocalFile, UserDisplayName, UserProfilePhoto} from "shared/types";
import {Member, MembersKey} from "../../../shared/entities";
import {JsonProperty} from "../../../shared/json/json-property";
import {JsonObject} from "../../../shared/json/json-object";
import {JSON_OBJECT} from "../../../shared/json/helpers";
import {md5_uuid} from "../../../shared/md5";
import {InboxKey} from "./messages";

export const PROTO_MESSAGE = 0;
export const PROTO_INDICATION = 1;

export const MESSAGE_TYPE_CHAT = 0;
export const MESSAGE_TYPE_CHAT_STATE = 1;
export const MESSAGE_TYPE_PHONECALL = 2;
export const MESSAGE_TYPE_COMMAND = 3;
export const MESSAGE_TYPE_SYNC = 4;

export const INDICATION_TYPE_SET_PRESENCE = 0;
export const INDICATION_TYPE_RESULT_PRESENCE = 1;
export const INDICATION_TYPE_SET_PRESENCE_SUBSCRIPTION = 2;
export const INDICATION_TYPE_TYPING = 3;

export function ChatThreadDisplayName(chatThread: ChatThread) {
  return "";
}

@JsonObject()
export class ChatThreadKey {

  @JsonProperty()
  id: string;

  static createDefault(): ChatThreadKey {
    return new ChatThreadKey("_default");
  }

  static createNew(): ChatThreadKey {
    return new ChatThreadKey(md5_uuid());
  }

  constructor(id: string) {
    this.id = id;
  }

  toJSON(): any {
    return {
      id: this.id,
    };
  }

  static fromJSON(json: any): ChatThreadKey {
    return new ChatThreadKey(json.id);
  }
}

export const SHARED_DATA_TYPE_MEDIA_ITEM = 1;
export const SHARED_DATA_TYPE_POST_ITEM = 2;
export const SHARED_DATA_TYPE_STICKER = 3;
export const SHARED_DATA_TYPE_LOCATION = 4;
export const SHARED_DATA_TYPE_GAME_INVITE = 5;
export const SHARED_DATA_TYPE_PHONECALL_INVITE = 6;

export interface SharedDataType {

  type(): number;

  id_(): string;

  isExpired(): boolean;

  toSharedDataItem(): ChatSharedDataItem<SharedDataType>;
}

export abstract class ChatBaseMediaItem implements BlobType {

  id: string;
  mimeType: string;
  orientation: string;
  length: number; // in bytes
  timestamp: number;
  displayName: string;
  listId?: string;
  thumbnailBlobId?: string;

  private blobId: string;

  static fromFileBase<T extends ChatBaseMediaItem>(item: T, file: LocalFile, thumbnailBlobId: string, id: string, mimeType: string, orientation: string, displayName: string, listId: string): T {
    item.id = id;
    item.mimeType = mimeType;
    item.orientation = orientation;
    item.length = file.size;
    item.timestamp = Date.now();
    item.displayName = displayName;
    item.listId = listId;
    item.thumbnailBlobId = thumbnailBlobId;
    return item;
  }

  getMimeType(): string {
    return this.mimeType;
  }

  getBlobId(): string {
    return this.blobId;
  }

  toJSON(): any {
    return {
      id: this.id,
      mimeType: this.mimeType,
      orientation: this.orientation,
      length: this.length,
      timestamp: this.timestamp,
      displayName: this.displayName,
      listId: this.listId,
      thumbnail_blob_id: this.thumbnailBlobId,
    };
  }

  static fromJSONPart<T extends ChatBaseMediaItem>(item: T, json: any): T {
    item.id = json.id;
    item.mimeType = json.mimeType;
    item.orientation = json.orientation;
    item.length = json.length;
    item.timestamp = json.timestamp;
    item.displayName = json.displayName;
    item.listId = json.listId;
    item.thumbnailBlobId = json["thumbnail_blob_id"];
    return item;
  }
}

export class ChatUploadRequest extends ChatBaseMediaItem {
}

export abstract class ChatMediaItem extends ChatBaseMediaItem {

  constructor(readonly local: boolean) {
    super();
  }
}

export class ChatSharedMediaItem extends ChatMediaItem implements SharedDataType {

  expiry: number = 0;

  constructor() {
    super(false);
  }

  id_(): string {
    return this.id;
  }

  isExpired(): boolean {
    return this.expiry > 0 && Date.now() > this.expiry;
  }

  toSharedDataItem(): ChatSharedDataItem<SharedDataType> {
    return new ChatSharedDataItem<ChatSharedMediaItem>(this, "File");
  }

  static fromSharedDataItem(sharedDataItem: ChatSharedDataItem<SharedDataType>): ChatSharedMediaItem {
    return sharedDataItem.data as ChatSharedMediaItem;
  }

  type(): number {
    return SHARED_DATA_TYPE_MEDIA_ITEM;
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      expiry: this.expiry,
    };
  }

  static fromJSON(json: any): ChatSharedMediaItem {
    let item = super.fromJSONPart(new ChatSharedMediaItem(), json);
    item.expiry = json.expiry;
    return item;
  }
}

export class ChatLocalMediaItem extends ChatMediaItem {

  file: LocalFile;

  constructor() {
    super(true);
  }

  static fromFile(file: LocalFile, thumbnailBlobId: string, id: string, mimeType: string, orientation: string, displayName: string, listId: string): ChatLocalMediaItem {
    let item: ChatLocalMediaItem = ChatBaseMediaItem.fromFileBase<ChatLocalMediaItem>(new ChatLocalMediaItem(), file, null, id, mimeType, orientation, displayName, listId);
    item.file = file;
    return item;
  }
}

export class ChatSharedDataItem<T extends SharedDataType> extends ChatBaseMediaItem {

  data: T;

  constructor(data?: T, displayName?: string) {
    super();
    this.id = uuid();
    this.mimeType = MimeTypeMap.MimeType_application_json;
    this.length = -1;
    this.timestamp = Date.now();
    this.displayName = displayName;
    this.listId = null;
    this.data = data;
  }

  toJSON(): any {
    return {
      ...super.toJSON(),
      type: this.data.type(),
      data: this.data,
    };
  }

  static fromJSON(json: any): ChatSharedDataItem<SharedDataType> {
    let item = ChatBaseMediaItem.fromJSONPart<ChatSharedDataItem<SharedDataType>>(new ChatSharedDataItem<SharedDataType>(), json);
    item.data = this.getData(json.type, json.data);
    return item;
  }

  static getData(type: number, data: any): SharedDataType {
    switch (type) {
      case SHARED_DATA_TYPE_MEDIA_ITEM:
        return ChatSharedMediaItem.fromJSON(data);
      case SHARED_DATA_TYPE_GAME_INVITE:
        // TODO:
        break;
      case SHARED_DATA_TYPE_PHONECALL_INVITE:
      // return ChatMeetingInvite.fromJSON(data);
    }
    return undefined;
  }
}

export class ChatMeta {

  static readonly TYPE_TEXT = 0;
  static readonly TYPE_DATE = 1;

  readonly metaType: number;
  readonly id: string;
  readonly data: string;


  constructor(metaType: number, id: string, data: string) {
    this.metaType = metaType;
    this.id = id;
    this.data = data;
  }
}

export enum ChatThreadType {
  CHAT = "chat",
  PHONECALL = "phonecall",
}

export interface ChatThreadContainer {

  members(): Member[];

  memberIds(): string[];
}

export interface ChatThreadContainerLoader {

  loadContainerRef(containerRef: string): Promise<ChatThreadContainer | null>;
}

@JsonObject()
export class ChatThread {

  _members: Member[];

  container: ChatThreadContainer;

  lastMessage: ChatMessage;

  @JsonProperty()
  membersKey: MembersKey;

  @JsonProperty()
  inboxKey: InboxKey;

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  type: ChatThreadType;

  @JsonProperty()
  _displayName: string;

  @JsonProperty()
  _profilePhoto: string;

  @JsonProperty()
  creator: string;

  @JsonProperty()
  created: number;

  @JsonProperty()
  _memberIds: string[];

  @JsonProperty()
  containerRef: string;

  @JsonProperty()
  lastMessageId: string;

  _myMemberIndex: number;

  static createNew(key: ChatThreadKey, type: ChatThreadType, displayName: string, profilePhoto: string, creator: string, created: number, memberIds: string[]) {
    return new ChatThread(key, type, displayName, profilePhoto, creator, created, memberIds, null);
  }

  constructor(key: ChatThreadKey, type: ChatThreadType, displayName: string, profilePhoto: string, creator: string, created: number, memberIds: string[], containerRef: string) {
    this.key = key;
    this.type = type;
    this._displayName = displayName;
    this._profilePhoto = profilePhoto;
    this.creator = creator;
    this.created = created;
    this._memberIds = memberIds;
    this.containerRef = containerRef;
  }

  profilePhoto() {
    return this._profilePhoto
      || (this._members.length === 2 && UserProfilePhoto(this._members[1 - this.myMemberIndex()].user));
  }

  displayName() {
    return this._displayName
      || this.members()?.filter(member => !member.isMe()).map(member => UserDisplayName(member.user)).join(", ")
      || "[ Unknown Thread ]";
  }

  myMemberIndex(): number {
    if (!this._myMemberIndex) {
      this._myMemberIndex = this.members()?.findIndex(member => member.isMe());
    }
    return this._myMemberIndex;
  }

  memberIds() {
    return this._memberIds || this.container?.memberIds();
  }

  myMemberId() {
    const index = this.myMemberIndex();
    if (index < 0) {
      return null;
    }
    return this.memberIds()[index];
  }

  members() {
    return this._members || this.container?.members();
  }

  myMember() {
    const index = this.myMemberIndex();
    if (index < 0) {
      return null;
    }
    return this.members()[index];
  }
}

@JsonObject()
export class ChatMessage implements JSONType {

  static readonly TYPE_UNKNOWN = 0;
  static readonly TYPE_META = 1;
  static readonly TYPE_TEXT = 2;
  static readonly TYPE_MESSAGE_STATE = 3;
  static readonly TYPE_SHARED_DATA_ITEM = 5;
  static readonly TYPE_PRESENCE = 6;
  static readonly TYPE_TYPING = 7;
  static readonly TYPE_PHONECALL = 8;
  static readonly TYPE_SERVER_NOTE = 9;
  static readonly TYPE_OUTDATED = 10;

  private static readonly TYPE_MAX = 0xFFFF;
  static readonly TYPE_UNAVAILABLE_META = ChatMessage.TYPE_MAX - 1;
  static readonly TYPE_UNAVAILABLE_TEXT = ChatMessage.TYPE_MAX - 2;

  @JsonProperty()
  messageId: string;

  @JsonProperty()
  from: string; // Always member id

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  timestamp: number;

  meta?: ChatMeta;
  text?: string;
  messageState?: ChatMessageState;
  sharedDataItem?: ChatSharedDataItem<SharedDataType>;
  presence?: ChatPresence;
  typing?: ChatTyping;
  serverNote?: ChatServerNote;
  quote?: ChatMessage;

  @JsonProperty()
  type: number;

  deleted: boolean;

  private data?: any;

  static createNew(from?: string, key?: ChatThreadKey) {
    return new ChatMessage(uuid(), from, key);
  }

  constructor(messageId: string, from?: string, key?: ChatThreadKey) {
    this.messageId = messageId;
    this.from = from;
    this.key = key;
  }

  isUserGenerated(requireValidThreadKey: boolean): boolean {
    return !requireValidThreadKey || !(this.key === null || this.key === undefined);
  }

  getOtherUsername(myUsername: string): string {
    return "";//StringUtil.getOtherUsername(myUsername, this.from, this.to);
  }

  isExpired(): boolean {
    return false;
  }

  copyTo(message: ChatMessage) {
    return message;
  }

  setMeta(meta: ChatMeta): ChatMessage {
    this.type = ChatMessage.TYPE_META;
    this.meta = meta;
    return this;
  }

  setText(text: string): ChatMessage {
    this.type = ChatMessage.TYPE_TEXT;
    this.text = text;
    return this;
  }

  setMessageState(messageState: ChatMessageState): ChatMessage {
    this.type = ChatMessage.TYPE_MESSAGE_STATE;
    this.messageState = messageState;
    return this;
  }

  setSharedDataItem(sharedDataItem: ChatSharedDataItem<SharedDataType>): ChatMessage {
    this.type = ChatMessage.TYPE_SHARED_DATA_ITEM;
    this.sharedDataItem = sharedDataItem;
    return this;
  }

  setPresence(presence: ChatPresence): ChatMessage {
    this.type = ChatMessage.TYPE_PRESENCE;
    this.presence = presence;
    return this;
  }

  setTyping(typing: ChatTyping): ChatMessage {
    this.type = ChatMessage.TYPE_TYPING;
    this.typing = typing;
    return this;
  }

  // setPhonecall(phonecall: ChatPhonecall): ChatMessage {
  //   this.type = ChatMessage.TYPE_PHONECALL;
  //   this.phonecall = phonecall;
  //   return this;
  // }

  setQuote(quote: ChatMessage): ChatMessage {
    this.quote = quote;
    return this;
  }

  setServerNote(serverNote: ChatServerNote): ChatMessage {
    this.type = ChatMessage.TYPE_SERVER_NOTE;
    this.serverNote = serverNote;
    return this;
  }

  writeData(): any {
    switch (this.type) {
      case ChatMessage.TYPE_TEXT:
        return this.text;
      case ChatMessage.TYPE_SHARED_DATA_ITEM:
        return this.sharedDataItem.toJSON();
    }
    return undefined;
  }

  readData(data: any): void {
    this.data = data;
    switch (this.type) {
      case ChatMessage.TYPE_TEXT:
        this.text = data as string;
        break;
      case ChatMessage.TYPE_SHARED_DATA_ITEM:
        this.sharedDataItem = ChatSharedDataItem.fromJSON(data);
        break;
    }
  }

  toJSON(): any {
    return {
      ...JSON_OBJECT.serializeObject(this),
      data: this.writeData(),
    };
  }

  static fromJSON(json: any): ChatMessage {
    const message = JSON_OBJECT.deserializeObject(json, ChatMessage);
    message.readData(json.data);
    return message;
  }
}

@JsonObject()
export class ChatMessageState {

  static readonly STATE_DELIVERED = 1;
  static readonly STATE_READ = 2;

  // sender -> server only
  to: string;

  @JsonProperty()
  from: string;

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  messageId: string;

  @JsonProperty()
  state: number;

  @JsonProperty()
  timestamp: number;

  constructor(from: string, key: ChatThreadKey, messageId: string, state: number, timestamp: number) {
    this.from = from;
    this.key = key;
    this.messageId = messageId;
    this.state = state;
    this.timestamp = timestamp;
  }
}

@JsonObject()
export class ChatSync {
}

@JsonObject()
export class ChatPresence {

  static readonly TYPE_HIDDEN = -1;
  static readonly TYPE_INACTIVE = 0;
  static readonly TYPE_ACTIVE = 1;

  @JsonProperty()
  from: string;

  @JsonProperty()
  timestamp: number;

  @JsonProperty()
  type: number;
}

@JsonObject()
export class ChatTyping {

  @JsonProperty()
  from: string;

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  timestamp: number;
}

@JsonObject()
export class ChatPresenceSubscription {

  @JsonProperty()
  from: string;

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  timestamp: number;

  @JsonProperty()
  subscribe: boolean;
}

@JsonObject()
export class ChatCommand {

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  timestamp: number;
}

@JsonObject()
export class ChatServerNote {

  static readonly TYPE_TEXT = 0;

  @JsonProperty()
  type: number;

  @JsonProperty()
  key: ChatThreadKey;

  @JsonProperty()
  timestamp: number;

  @JsonProperty()
  data: any;
}

export class MessageFactory {

  static createForwardedMessage(from: string, key: ChatThreadKey, timestamp: number, forward: ChatMessage): ChatMessage {
    let message = ChatMessage.createNew(from, key);
    forward.copyTo(message);
    message.timestamp = timestamp;
    return message;
  }

  static createMetaMessage(timestamp: number): ChatMessage {
    let message = ChatMessage.createNew();
    message.setMeta(new ChatMeta(ChatMeta.TYPE_DATE, uuid(), "" + timestamp));
    message.timestamp = timestamp;
    return message;
  }

  static createMessageStateMessage(state: ChatMessageState): ChatMessage {
    let message = ChatMessage.createNew();
    message.setMessageState(state);
    return message;
  }

  static createPresenceMessage(presence: ChatPresence): ChatMessage {
    let message = ChatMessage.createNew(presence.from);
    message.setPresence(presence);
    return message;
  }

  static createTypingMessage(typing: ChatTyping): ChatMessage {
    let message = ChatMessage.createNew(typing.from, typing.key);
    message.setTyping(typing);
    return message;
  }

  static createTextMessage(from: string, key: ChatThreadKey, timestamp: number, text: string, quote?: ChatMessage): ChatMessage {
    let message = ChatMessage.createNew(from, key);
    message.timestamp = timestamp;
    message.setText(text);
    //message.setQuote(quote);
    return message;
  }

  static createSharedDataItemMessage(from: string, key: ChatThreadKey, timestamp: number, sharedDataItem: ChatSharedDataItem<SharedDataType>, quote?: ChatMessage): ChatMessage {
    const message = ChatMessage.createNew(from, key);
    message.timestamp = timestamp;
    message.setSharedDataItem(sharedDataItem);
    message.setQuote(quote);
    return message;
  }

  static createServerNoteMessage(from: string, key: ChatThreadKey, timestamp: number, text: string): ChatMessage {
    const message = ChatMessage.createNew(from, key);
    message.timestamp = timestamp;
    const chatServerNote = new ChatServerNote();
    chatServerNote.type = ChatServerNote.TYPE_TEXT;
    chatServerNote.data = text;
    message.setServerNote(chatServerNote);
    return message;
  }
}

export interface ChatProtocolListener {
  // Client <-> Server

  onChatMessage(message: ChatMessage): boolean;

  onChatStateMessage(messageState: ChatMessageState): boolean;

  onSyncMessage(sync: ChatSync): boolean;

  onSetPresenceIndication(presence: ChatPresence): boolean;

  onResultPresenceIndication(presence: ChatPresence): boolean;

  onTypingIndication(typing: ChatTyping): boolean;

  // Client -> Server

  onPresenceSubscriptionIndication(presenceSubscription: ChatPresenceSubscription): boolean;

  // Server -> Client

  onCommandMessage(command: ChatCommand): boolean;
}

export abstract class ChatProtocolObject {

  type: number;
}

export class ChatProtocolPacket {

  constructor(readonly proto: number, readonly object: ChatProtocolObject) {
  }
}

export class ChatProtocol {

  static decode(payload: Uint8Array, listener: ChatProtocolListener): boolean {
    let packet = JSON.parse(new TextDecoder("utf-8").decode(payload));
    return this.decodeInternal(packet, listener);
  }

  static decodeInternal(packet: ChatProtocolPacket, listener: ChatProtocolListener): boolean {
    switch (packet.proto) {
      case PROTO_MESSAGE:
        return this.decodeMessage(packet.object as ChatProtocolObjectMessage, listener);
      case PROTO_INDICATION:
        return this.decodeIndication(packet.object as ChatProtocolObjectIndication, listener);
      default:
        throw new Error("Invalid proto: " + packet.proto);
    }
  }

  // Message

  static encodeChatMessage(message: ChatMessage): ChatProtocolPacket {
    let body = {message: message};
    return this.encodeMessage(MESSAGE_TYPE_CHAT, body);
  }

  static decodeChatMessage(message: ChatProtocolObjectMessage, listener: ChatProtocolListener): boolean {
    let msg = JSON_OBJECT.deserializeObject(message.body["message"], ChatMessage);
    return listener.onChatMessage(msg);
  }

  static encodeChatStateMessage(messageState: ChatMessage): ChatProtocolPacket {
    let body = {message_state: messageState};
    return this.encodeMessage(MESSAGE_TYPE_CHAT_STATE, body);
  }

  static decodeChatStateMessage(message: ChatProtocolObjectMessage, listener: ChatProtocolListener): boolean {
    let messageState = JSON_OBJECT.deserializeObject(message.body["message_state"], ChatMessageState);
    return listener.onChatStateMessage(messageState);
  }

  static encodeSyncMessage(sync: ChatSync):
    ChatProtocolPacket {
    let body = {sync: sync};
    return this.encodeMessage(MESSAGE_TYPE_SYNC, body);
  }

  static decodeSyncMessage(message: ChatProtocolObjectMessage, listener: ChatProtocolListener): boolean {
    let sync = JSON_OBJECT.deserializeObject(message.body["sync"], ChatSync);
    return listener.onSyncMessage(sync);
  }

  static encodeCommandMessage(command: ChatCommand): ChatProtocolPacket {
    let body = {command: command};
    return this.encodeMessage(MESSAGE_TYPE_COMMAND, body);
  }

  static decodeCommandMessage(message: ChatProtocolObjectMessage, listener: ChatProtocolListener): boolean {
    let command = JSON_OBJECT.deserializeObject(message.body["command"], ChatCommand);
    return listener.onCommandMessage(command);
  }

  // Indication

  static encodeSetPresenceIndication(presence: ChatPresence) {
    let body = {presence: presence};
    return this.encodeIndication(INDICATION_TYPE_SET_PRESENCE, body);
  }

  static decodeSetPresenceIndication(indication: ChatProtocolObjectIndication, listener: ChatProtocolListener): boolean {
    let presence = JSON_OBJECT.deserializeObject(indication.body["presence"], ChatPresence);
    return listener.onSetPresenceIndication(presence);
  }

  static encodeResultPresenceIndication(presence: ChatPresence) {
    let body = {presence: presence};
    return this.encodeIndication(INDICATION_TYPE_RESULT_PRESENCE, body);
  }

  static decodeResultPresenceIndication(indication: ChatProtocolObjectIndication, listener: ChatProtocolListener): boolean {
    let presence = JSON_OBJECT.deserializeObject(indication.body["presence"], ChatPresence);
    return listener.onResultPresenceIndication(presence);
  }

  static encodeSetPresenceSubscriptionIndication(presenceSubscription: ChatPresenceSubscription): ChatProtocolPacket {
    let body = {presenceSubscription: presenceSubscription};
    return this.encodeIndication(INDICATION_TYPE_SET_PRESENCE_SUBSCRIPTION, body);
  }

  static decodeSetPresenceSubscriptionIndication(indication: ChatProtocolObjectIndication, listener: ChatProtocolListener): boolean {
    let presenceSubscription = JSON_OBJECT.deserializeObject(indication.body["presenceSubscription"], ChatPresenceSubscription);
    return listener.onPresenceSubscriptionIndication(presenceSubscription);
  }

  static encodeTypingIndication(typing: ChatTyping): ChatProtocolPacket {
    let body = {typing: typing};
    return this.encodeIndication(INDICATION_TYPE_TYPING, body);
  }

  static decodeTypingIndication(indication: ChatProtocolObjectIndication, listener: ChatProtocolListener): boolean {
    let typing = JSON_OBJECT.deserializeObject(indication.body["typing"], ChatTyping);
    return listener.onTypingIndication(typing);
  }

  // Util methods

  private static encodeMessage(type: number, body: any): ChatProtocolPacket {
    const message = new ChatProtocolObjectMessage();
    message.type = type;
    message.body = body;
    return new ChatProtocolPacket(PROTO_MESSAGE, message);
  }

  private static decodeMessage(message: ChatProtocolObjectMessage, listener: ChatProtocolListener): boolean {
    switch (message.type) {
      case MESSAGE_TYPE_CHAT:
        return ChatProtocol.decodeChatMessage(message, listener);
      case MESSAGE_TYPE_CHAT_STATE:
        return ChatProtocol.decodeChatStateMessage(message, listener);
      case MESSAGE_TYPE_PHONECALL:
        // return ChatProtocol.decodePhonecallMessage(message, listener);
        break;
      case MESSAGE_TYPE_COMMAND:
        return ChatProtocol.decodeCommandMessage(message, listener);
      case MESSAGE_TYPE_SYNC:
        return ChatProtocol.decodeSyncMessage(message, listener);
      default:
        throw new Error("Invalid message type: " + message.type);
    }
    return false;
  }

  private static encodeIndication(type: number, body: any): ChatProtocolPacket {
    let indication = new ChatProtocolObjectIndication();
    indication.type = type;
    indication.body = body;
    return new ChatProtocolPacket(PROTO_INDICATION, indication);
  }

  private static decodeIndication(indication: ChatProtocolObjectIndication, listener: ChatProtocolListener): boolean {
    switch (indication.type) {
      case INDICATION_TYPE_SET_PRESENCE:
        return ChatProtocol.decodeSetPresenceIndication(indication, listener);
      case INDICATION_TYPE_RESULT_PRESENCE:
        return ChatProtocol.decodeResultPresenceIndication(indication, listener);
      case INDICATION_TYPE_SET_PRESENCE_SUBSCRIPTION:
        return ChatProtocol.decodeSetPresenceSubscriptionIndication(indication, listener);
      case INDICATION_TYPE_TYPING:
        return ChatProtocol.decodeTypingIndication(indication, listener);
      default:
        throw new Error("Invalid indication type: " + indication.type);
    }
    return false;
  }
}

export class ChatProtocolObjectMessage extends ChatProtocolObject {
  body: any;
}

export class ChatProtocolObjectIndication extends ChatProtocolObject {
  body: any;
}
